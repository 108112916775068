.contacts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-between;
  margin-bottom: 0.75rem;
  padding-left: 333px;
  background: transparent url(/img/contacts_bg.png) no-repeat scroll 75px 35px;

  @media #{$small-and-down} {
    background: none;
    padding-left: 0;
  }

  p {
    font-family: Cuprum, Arial, sans-serif;
  }
  &__float {
    // float: left;
    // width: 265px;
    padding: 0.75rem;
  }
  &__title {
    font-size: 18px;
    text-transform: uppercase;
    margin: 5px 0;
    font-weight: 700;
  }
  &__text {
    font-size: 14px;
    margin: 7px 0;
  }
}

.map {
  display: flex;
}
