.good {
  // background: #fff;
  // width: 100%;
  // height: 500px;
  position: relative;
  // font-family: Cuprum, Arial, MS Trebuchet, sans-serif;
  font-family: Roboto, Arial, Sans-Serif;
  // margin: 10px 0;

  &-gallery {
    float: left;
    width: 470px;


    &__inner {
      position: relative;
    }

    &__thumbs {
      float: left;
      margin-right: 24px;
    }

    &__viewport {
      overflow: hidden;
      height: 400px;
      // cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAABY0lEQ…tU5N6nspmpapJeNX2/6nd61URVLqRX6UTpVS42/4cPfwEmTNNJYUQZAAAAAABJRU5ErkJggg==),
      //   zoom-in;
    }

    &__main-image {
      height: 100%;
      margin: auto;
      z-index: 1;
    }

    &__image {
      display: none;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
      position: relative;
      transform: translateY(-50%);
      top: 50%;
      &-current {
        display: block;
      }
    }

    @media #{$small-and-down} {
      margin: 0 0 0.75rem;

      &__viewport {
        height: auto;
      }

      &__image {
        transform: none;
      }
    }

    @media (max-width: 1240px) {
      float: none;
      overflow: hidden;
      width: auto;
    }
  }

  &-carousel {
    display: inline-block;
    position: relative;

    &__item {
      margin: 0;
      padding: 6px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
    &__vertical {
      height: 400px;
    }

    &__vertical &__next,
    &__vertical &__prev {
      background-color: #fff;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 24px;
      border-radius: 50%;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      display: none;
      height: 36px;
      opacity: 0.9;
      position: absolute;
      width: 36px;
      z-index: 1;
      cursor: pointer;
      left: calc(50% - 18px);
      right: auto;
    }

    &__vertical &__next {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAh0lEQVR4Ae3PMRXCUBAF0S8pkhCxowMcEIeRsLQUdO/wt8hcBTNLkiRJkm4HeAHHCgFHVT3XTlV1Al1VF3CE8RfQwHtrPNDfE2F8b5sAHkD/mgjju6rOlcsn8vj5iTx+fiKPn5+Yj88nwvj5iTB+fiKIH53I4+cn8vj5iTx+fuJf8ZIkSZL0Ac6ARM7g7vpaAAAAAElFTkSuQmCC');
      bottom: -18px;
      top: auto;

      &:hover {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAfUlEQVR4Ae3PsQ2AQAwEQZf0Jbkj6AA6pATj4CMIT/wF7EhOrb0AAAAAgD/a+0boRt8Wi5191XeJI8b8UX3H6vgSRjzja+WI7CtxxCt+3hk6fYQ/Xh/hj9dH+OP1Ef54fYQ/XhwhxvtHGOJl6Y/XpT9el/54XX4VDwAAAAA3XFt3ePQPNfIAAAAASUVORK5CYII=');
      }
    }
    &__vertical &__prev {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAaUlEQVR4Ae2RtxWEUBDEtqtr7DDhlkwFeOLh8QaPNLn0TcB2AACgyCKP1XfD8li9Trh6O6H1fmJd3wwzE1r/G6YTnj4G/ITU+wmt9xNa7yeE3kqIwKKXCeMOi14mjF+oF71O1AEAAHA1PTgEu+lPfCwsAAAAAElFTkSuQmCC');
      top: -18px;

      &:hover {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAZUlEQVR4Ae2PuRWAMAzF2IrVKDMyG4B7NRy5I/3WT3re5AMiIpJiRfVXLBXVI5FXj0RePRP59WcMiZz6PYZETn2QNUF91gT1WRPUZ01QnzuRqA+Q+PsD9UhA/4KDeibiqjUiIiI3PSx3W7K8HOkAAAAASUVORK5CYII=');
      }
    }

    &__enabled {
      display: block;
    }

    &__scroller {
      height: 400px;
      overflow: hidden;
      ol {
        list-style: none;
        margin: 0;
      }
    }

    &__thumb {
      border: 1px solid #e4e4e4;
      border-radius: 5px;
      cursor: pointer;
      display: block;
      outline: none;
      overflow: hidden;
      padding: 3px;
      position: relative;
      text-align: center;
      vertical-align: middle;
      width: 60px;
      height: 60px;
      img {
        display: inline-block;
        max-height: 100%;
        max-width: 100%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      &-current {
        border: 2px solid #1a73e8;
        cursor: default;
        padding: 2px;
      }
    }

  }

  figure.zoom {
    & img:hover {
      opacity: 0;
    }
    img {
      transition: opacity 0.5s;
      display: block;
      width: 100%;
    }
    background-position: 50% 50%;
    position: relative;
    width: 500px;
    overflow: hidden;
    cursor: zoom-in;
  }

  &__right {
    float: right;
    margin-left: 1.5rem;

    // position: absolute;
    // left: 100%;
    // top: 0;
    // margin-left: -227px;
    // width: 227px;
    // height: 100%;
    // overflow: hidden;
    // background: #fdcf09;

    &-wrap {
      background: #fdcf09;
      border-radius: 4px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
      padding: 1.5rem;
      position: relative;
      width: 297px;

      @media #{$small-and-down} {
        width: 100%;
      }

      // padding: 15px;
      // text-align: center;
      & > .separator {
        background: #e3ba08;
        height: 1px;
        margin: 15px 0;
      }
    }

    @media #{$small-and-down} {
      float: none;
      margin: 0 0 0.75rem;
    }
  }

  &__descr {
    @extend .clearfix;
    // font-size: 14px;
    // overflow: hidden;
    // position: absolute;
    // right: 240px;
    // top: 15px;
    // width: 195px;

    @media (max-width: 1240px) {
      clear: both;
      overflow: visible;
    }

    &-wrap {
      overflow: hidden;
      padding-left: 24px;

      @media (max-width: 1240px) {
        margin-top: $gutter-width/2;
        overflow: visible;
        padding-left: 0;
      }
    }
  }

  &__reviews {
    clear: both;
  }

  &__editlink {
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    display: none;
    height: 36px;
    // opacity: 0.9;
    opacity: 0.5;
    position: absolute;
    width: 36px;
    z-index: 1;
    text-align: center;
    top: 0.5rem;
    right: 0.5rem;
    transition: 0.3s;
    color: $off-black;

    &:hover {
      opacity: 1;
    }

    :hover > & {
      display: block;
    }

    > i {
      line-height: 36px;

      img.svg {
        vertical-align: middle;
      }
    }
  }

  &__price {
    margin-bottom: 15px;
    text-align: left;
    font-size: 14px;
    position: relative;

    &-title {
      // text-align: left;
    }

    &-main,
    &-old {
      white-space: nowrap;
      display: inline-block;
    }

    &-main {
      display: block;
      line-height: 1.2;
      font-size: 36px;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
    }

    &-old {
      // color: rgba(255, 0, 0, 0.87);
      // color: #757575;
      // font-size: 20px;

      // margin-right: 0.75rem;
      // line-height: 1;

      margin: 4px 0 0 12px;
      line-height: 17px;

      &-value {
        text-decoration: line-through;
      }
    }
  }

  &__colors {
    margin: 0 0 0.75rem;
    // display: flex;

    &-title {
      margin-bottom: 0.5rem;
    }

    &-item {
      border: 1px solid #e4e4e4;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      outline: none;
      overflow: hidden;
      padding: 3px;
      position: relative;
      text-align: center;
      vertical-align: middle;
      width: 52px;
      height: 52px;
      margin-right: 0.5rem;

      &:first-child {
        // margin-left: 0;
      }

      &-selected {
        border: 2px solid #1a73e8;
        cursor: default;
        padding: 2px;
      }

      img {
        display: inline-block;
        max-height: 100%;
        max-width: 100%;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }
  }

  &__file {
    // text-align: center;
    margin-top: 0.75rem;
    &-btn {
    }
    &-open-new {
      color: $accent-color;
    }
  }

  &__information {
    // margin-top: 0.75rem;
    table {
      table-layout: fixed;
      border-spacing: 0;
      padding: 0;
      border-bottom: 1px solid #e7e7e7;
      width: 100%;
      tr {
        &:first-child td,
        &:first-child th {
          // padding-top: 0;
        }
      }

      th {
        background-color: #f3f3f3;
        color: #111;
        font-weight: 400;
        width: 50% !important;
        white-space: normal;
        word-wrap: break-word;
      }
      td {
        color: #333;
      }
      td:last-child,
      th:last-child {
        padding-right: 0;
      }
      td,
      th {
        border-top: 1px solid #e7e7e7;
        padding: 7px 14px 6px;
      }
    }
  }

  &__name {
    text-transform: uppercase;
    font-size: 17px;
    text-align: center;
    margin: 0;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e3ba08;
    font-weight: 400;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
    color: #484848;

    @media #{$small-and-down} {
      br {
        display: none;
      }
    }
  }

  &__series {
    font-weight: bold;
    font-size: 1.2em;
  }

  &__description {
    font-family: Roboto, Arial, Sans-Serif;
    margin: 0 0 $gutter-width/2;
    text-align: justify;

    & > :first-child {
      margin-top: 0;
    }
  }

  &__advantages {
    margin: 0 0 $gutter-width/2;

    &-item {
      font-size: 14px;
      padding-left: 1.75rem;
      background-color: transparent;
      background-position: 0 50%;
      background-repeat: no-repeat;
      margin: 0 0 0.25rem;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2BpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0idXVpZDpCRDg5RjI0MTQ1MjJERjExQUUzQ0I1OTJCRTU2MUNGMCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo0RjVBN0E3MzMzNDQxMUU0ODdGODg4OTRBRkE3REJFMCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo0RjVBN0E3MjMzNDQxMUU0ODdGODg4OTRBRkE3REJFMCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQzNzlFNUFCODMxOUU0MTFCODg1RTgzNzBENEE4MDQwIiBzdFJlZjpkb2N1bWVudElEPSJ1dWlkOkJEODlGMjQxNDUyMkRGMTFBRTNDQjU5MkJFNTYxQ0YwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+NPuGQAAAAZRJREFUeNpi/HuekwEN8ABxAhB7A7EWEMsB8SMgvgbEW4F4ARB/gSlmMvjGwIhmSCAQTwNiCQbc4AUQZwHxepghTEiSFUC8joABDFB5kLpquGugdDgQtzOQBlqAOARmCCgMeknRffn2P4baqb9BzAn/LnDxgAyJBWJpYg3Yeewvg33SLwYXC2YGqL5YJmhgEgWmrvjD4Jv7i6EknoXB3hgenIEsQEKXkOa//xgYCrt/gw1xt2JmqEphQZbWBRknhCyycBPIuT/Bfn7x9j/D958MDMFFv8AGyEkwMixsYUW3QwiUTp4jR+udx/8ZnFJ+Mjx99Z+BGWiFtBgjw6MX/xnYgHp3z2RnsDFkwkg3IJHLyCIqsowM++awgzWDvAEyAARivJmxGQAC15hgKQ+XQSAACoepVWy4gmwNyDugdHIbW0oFeQ0UoAubWRmE+BlxZQFVWN4BpdgVDKSDCGDeWQnz5EogriHRgBqoPpQM2ArEoUD8moDm11B1rTABRhzlSTK0PNGFhtULaCyCypO56OUJQIABAHg7eCYJT1dOAAAAAElFTkSuQmCC");
    }
  }
}

.separator {
  height: 1px;
  background: #d9d9d9;
}

.tocart {
  // border-radius: 4px;
  display: block !important;
  background-color: #fff;
  // &:hover {
  //   background-color: #e0e0e0;
  // }
  // &:focus {
  //   background-color: #dadada;
  // }
}

.quick-buy {
  display: block !important;
}

.information {
  cursor: help;
  color: var(--color-accent);
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  border-radius: 50%;
  border: 2px solid var(--color-accent);
  width: 28px;
  height: 28px;

  img.svg {
    width: 24px;
    height: 24px;
  }
}

.good .separator {
  // height: 1px;
  // background: #d9d9d9;
  // margin: 15px 0;
}
.good .good_left_block .good_img {
  width: 470px;
  height: 350px;
  position: relative;
  margin: 14px 15px 15px;
}
.good .good_left_block .under_good_img {
  margin: 15px 242px 15px 15px;
}
.good .good_left_block .under_good_img .good_colors {
  float: left;
}
.good .good_left_block .under_good_img .good_colors .item_color {
  float: left;
  margin: 0 10px 0 0;
  border: 2px solid #000;
  cursor: pointer;
  box-shadow: none;
  width: 50px;
  height: 50px;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.good .good_left_block .under_good_img .good_colors .selected {
  border: 2px solid tomato;
  box-shadow: 0 0 5px 2px tomato;
}
.mebel_item_color_catalog {
  width: 50px;
  height: 50px;
}
.good .good_left_block .under_good_img .good_instruction {
  float: right;
  background: transparent url(/img/targetblank.gif) no-repeat scroll 100% 50%;
  padding: 0 15px 0 0;
  margin-top: 55px;
}
.good .good_left_block .under_good_img .good_instruction a {
  text-decoration: none;
}
.good
  .good_left_block
  .under_good_img
  .good_instruction
  a
  span.puz_good_extension {
  text-decoration: none;
  color: #fff;
  padding: 9px 7px;
  background: #fdcf09;
  margin-right: 5px;
  text-transform: uppercase;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.good
  .good_left_block
  .under_good_img
  .good_instruction
  a:hover
  span.puz_good_extension {
  background: #ff0;
  color: #000;
}
.good .good_right_block .separator {
  background: #e3ba08;
}
.good .good_right_block .good_right_top_block h2 {
  // text-transform: uppercase;
  // font-size: 17px;
  // text-align: center;
  // margin: 0;
  // padding-bottom: 20px;
  // margin-bottom: 15px;
  // border-bottom: 1px solid #e3ba08;
  // font-weight: 400;
  // text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.75);
  // color: #484848;
}
.good .good_right_block .good_right_top_block .white {
  // font-weight: bold;
  // font-size: 1.2em;
  // color: #fff;
  // text-shadow: 0 -1px 0 hsla(0, 0%, 76%, 0.75);
}
.good .good_right_block .good_right_top_block .red {
  color: red;
}
.good .good_right_block .good_right_top_block .good_price span.valuta {
  font-size: 18px;
  margin-left: 10px;
}
// .good_in_basket {
//   text-align: center;
// }
// .good_in_basket a {
//   display: block;
//   background: transparent url(/img/button.png) no-repeat scroll 0 0;
//   width: 187px;
//   // height: 29px;
//   margin: 0 auto;
//   font-family: Cuprum, Arial, sans-serif;
//   font-size: 18px;
//   text-transform: uppercase;
//   overflow: hidden;
//   // padding: 9px 0;
//   line-height: 36px;
//   text-align: center;
//   cursor: pointer;
//   text-decoration: none;
// }
.good .good_right_block .good_full_description {
  border-left: 1px solid #ccc;
  height: 100%;
  padding: 15px 15px 45px;
  overflow: hidden;
}
.good .good_right_block .good_full_description b {
  font-family: Cuprum;
  font-size: 13px;
  font-weight: 700;
}
.good_in_social {
  background: #757575;
  display: inline-block;
  color: #fff;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  -khtml-border-radius: 6px;
}
.good_in_social .yashare-auto-init {
  float: right;
}
.good_in_social .share_link {
  display: inline-block;
  margin: 5px 0 0 5px;
  font-family: Cuprum;
  font-size: 14px;
}
.good .buy_in_one_click {
  text-align: center;
}
.good .buy_in_one_click label {
  font-size: 14px;
  font-style: italic;
}
.good .buy_in_one_click input {
  border: 2px solid #fff;
  font-size: 18px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
  line-height: 30px;
  padding: 0 5px;
  background: #fff;
  color: #222;
}
.good .buy_in_one_click input.empty {
  border: 2px solid red;
}
// .good .one_click_submit {
//   width: 175px;
//   margin: 0 auto;
// }

.good .good_right_block2 .good_type label {
  font-family: times new roman;
  font-size: 12px;
  font-style: italic;
}
.good .good_right_block2 .good_type p {
  text-transform: uppercase;
  font-family: Cuprum, Arial, sans-serif;
  font-size: 18px;
  margin: 5px 0;
}
