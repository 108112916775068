.colorTip {
  display: none;
  position: absolute;
  left: 50%;
  top: -30px;
  padding: 6px;
  background-color: #fff;
  font: 14px Cuprum;
  text-decoration: none;
  text-align: center;
  text-shadow: 0 0 1px #fff;
  white-space: nowrap;
  text-transform: uppercase;
}
.pointyTip,
.pointyTipShadow {
  border: 6px solid transparent;
  bottom: -12px;
  height: 0;
  left: 50%;
  margin-left: -6px;
  position: absolute;
  width: 0;
}
.pointyTipShadow {
  border-width: 7px;
  bottom: -14px;
  margin-left: -7px;
}
.colorTipContainer {
  position: relative;
  text-decoration: none !important;
  z-index: 1;
}
.colorTipContainer:hover {
  z-index: 2;
}
.white .pointyTip {
  border-top-color: #fff;
}
.white .pointyTipShadow {
  border-top-color: #ddd;
}
.white .colorTip {
  box-shadow: 0 1px 4px #999;
  background-color: #fff;
  color: #333;
}
.yellow .pointyTip {
  border-top-color: #f9f2ba;
}
.yellow .pointyTipShadow {
  border-top-color: #e9d315;
}
.yellow .colorTip {
  background-color: #f9f2ba;
  border: 1px solid #e9d315;
  color: #5b5316;
}
.blue .pointyTip {
  border-top-color: #d9f1fb;
}
.blue .pointyTipShadow {
  border-top-color: #7fcdee;
}
.blue .colorTip {
  background-color: #d9f1fb;
  border: 1px solid #7fcdee;
  color: #1b475a;
}
.green .pointyTip {
  border-top-color: #f2fdf1;
}
.green .pointyTipShadow {
  border-top-color: #b6e184;
}
.green .colorTip {
  background-color: #f2fdf1;
  border: 1px solid #b6e184;
  color: #558221;
}
.red .pointyTip {
  border-top-color: #bb3b1d;
}
.red .pointyTipShadow {
  border-top-color: #8f2a0f;
}
.red .colorTip {
  background-color: #bb3b1d;
  border: 1px solid #8f2a0f;
  color: #fcfcfc;
  text-shadow: none;
}
.black .pointyTip {
  border-top-color: #333;
}
.black .pointyTipShadow {
  border-top-color: #111;
}
.black .colorTip {
  background-color: #333;
  border: 1px solid #111;
  color: #fcfcfc;
  text-shadow: none;
}