.cat {
  &__editlink {
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    display: inline-block;
    height: 36px;
    opacity: 0;
    width: 36px;
    z-index: 1;
    text-align: center;
    margin-left: 0.5rem;
    transition: 0.3s;

    :hover > & {
      opacity: 0.5;
    }
    &:hover {
      opacity: 1;
    }

    > i {
      line-height: 36px;
    }
  }
}
