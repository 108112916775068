.scrolldown {
  position: sticky;
  bottom: 10px;

  div {
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 30px;
    height: 30px;
    transform: translateY(-60px) translateX(-50%) rotate(45deg);
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    animation: scrolldown 1.5s linear infinite;
    opacity: 0;

    &:nth-child(1) {
      transform: translate(-15px, -15px);
      animation-delay: -0.4s;
    }

    &:nth-child(2) {
      transform: translate(0, 0);
      animation-delay: -0.2s;
    }

    &:nth-child(3) {
      transform: translate(15px, 15px);
      animation-delay: 0s;
    }
  }
}
@keyframes scrolldown {
  0% {
    top: -5px;
    left: -5px;
    opacity: 0;
  }
  25% {
    top: 0px;
    left: 0px;
    opacity: 1;
  }
  100% {
    top: 5px;
    left: 5px;
    opacity: 0;
  }
}
