#nav {
  // margin: 0.75rem 0;
  // font-family: Trebuchet MS, sans-serif;
  font-family: Cuprum, sans-serif;
  color: #808080;
  font-size: 14px;
  // display: flex;
  // align-items: center;
  // flex-wrap: wrap;
  text-transform: uppercase;

  a {
    color: #808080;
    font-size: 12px;
    text-decoration: none;
    &:hover {
      color: #000;
    }
  }

  span {
    font-size: 12px;
  }
}
