.header {
  &__top {
    line-height: 2.2;
    font-family: Cuprum;
    
    &-inner {
      display: flex;
      justify-content: space-between;
    }

    &-menu {
      > ul {
        display: flex;
        > li {
          margin-left: $gutter-width/2;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
    
  }
}
