.page {
  &__title {
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
    color: #484848;
    font-family: Cuprum;
    font-size: 30px;
    line-height: 1;
    // text-transform: uppercase;
    font-weight: 400;
    margin: $gutter-width 0;
    // border-bottom: 1px solid #cfd1d3;

    &:after {
    }
  }

  &__label {
    margin-right: $gutter-width/2;
    & > span {
      display: inline-block;
      font-size: 13px;
      padding: 3px 5px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      text-shadow: none;
      margin-left: 0.5rem;
      border-radius: 4px;
      &:first-child {
        margin-left: 0;
      }
    }
    &-newbie {
      background: var(--color-newbie);
    }
    &-leader {
      background: var(--color-leader);
      color: #fff;
    }
    &-sale {
      background: var(--color-sale);
    }
    @media #{$small-and-down} {
      // styles for small screens and down
      display: block;
      margin-right: 0;
    }
  }

  &__content {
    position: relative;
    text-align: left;

    &_before,
    &_after {
      img {
        width: 100% !important;
        height: auto !important;
        float: none !important;
      }
    }
  }
  
}

.above_content img {
  width: 100% !important;
  height: auto !important;
}
