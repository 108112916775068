#select_town {
  width: 160px;
  margin: 0;
  height: 44px;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#town {
  width: 160px;
  font-size: 12px;
}
#cuselFrame-town,
#cuselFrame-town .cuselFrameRight {
  text-transform: none;
}
#your_town {
  float: right;
  margin: 12px 5px 0 0;
  font-family: Cuprum, Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}


#search,
#search input {
  background: #8d8f92;
}
#search input {
  font-family: Cuprum;
  color: #fff;
  border: 0;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  box-sizing: border-box;
  height: 44px;
  padding: 0 10px 0 54px;
  margin: 0;
  width: 100%;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
#search input:hover {
  background: #666;
}
#search input:focus {
  background: #404040;
  color: #fdc501;
  text-transform: uppercase;
  font-style: normal;
  font-size: 18px;
}
#search button {
  position: absolute;
  background: #8d8f92;
  margin: 0;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 44px;
  color: #fdcf09;
  height: 44px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
#search button:hover {
  background: #000;
}

span.collapse {
  display: block;
  background: transparent url(/img/button.png) no-repeat scroll 0 0;
  width: 187px;
  margin: 0 auto;
  font-family: Cuprum, Arial, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  overflow: hidden;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}

#news_block {
  overflow: hidden;
  margin: 40px 0;
}
#news_block h3 {
  font-family: Cuprum, Arial, sans-serif;
  font-size: 24px;
  text-transform: uppercase;
}
#news_block a {
  color: #ffd925;
}
#news_item_left {
  width: 465px;
  float: left;
}
#news_item_right {
  width: 465px;
  float: right;
}
#copyrights {
  float: right;
  margin: 25px 0 0;
  color: #777;
  font-family: Cuprum, Arial, sans-serif;
  font-style: italic;
}
#bottom_menu {
  float: left;
  margin: 30px 0 0 300px;
}
#bottom_menu a {
  color: #777;
  font-family: Cuprum, Arial, sans-serif;
  margin: 0 10px 0 0;
  font-style: italic;
}
#bottom_menu a.active,
#made {
  color: #000;
}
#made {
  float: right;
  margin: 4px 0 0;
}
#made a {
  color: #777;
  font-family: Cuprum, Arial, sans-serif;
  font-style: italic;
}
#content h1,
#header h1 {
  font-family: Cuprum, Arial, sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 400;
  margin: 25px 0 10px;
}
#content h1 {
  background: url(/img/header_bottom_line.gif) repeat-x scroll 0 100% transparent;
}
#content h1,
#content h2 {
  text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.75);
  color: #484848;
}
#content h2 {
  font-family: Cuprum, Arial, sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  text-transform: uppercase;
}
#content_text {
  font-size: 15px;
  margin: 15px 0 0;
  position: relative;
}
.button_red {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  background: transparent url(/img/button_red.png) no-repeat scroll 0 0;
  color: #fff;
  text-transform: uppercase;
  font-family: Cuprum, Arial, sans-serif;
  font-size: 18px;
  width: 175px;
  height: 37px;
  cursor: pointer;
}
.margintop80 {
  margin-top: 90px;
}

.basketButton .add_to_cart {
  display: block;
  background: transparent url(/img/button.png) no-repeat scroll 0 0;
  width: 187px;
  height: 29px;
  margin: 0 auto;
  font-family: Cuprum, Arial, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  overflow: hidden;
  padding-top: 7px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}
#basket_goods {
  background-color: #fdcf09;
  display: none;
  left: 0;
  position: absolute;
  top: 44px;
  z-index: 14;
}
.show {
  display: block !important;
}
#mebel_top_basket_goods_popup_additional a {
  color: #fafafa;
  display: block;
  text-decoration: none;
  text-align: center;
  background-color: #666666;
  padding: 0.5rem 0.75rem;
  transition: 0.2s ease;

  &:hover {
    background-color: #404040;
  }
}
#basket_goods_list {
  padding-left: 20px;
  width: 350px;
  margin: 20px 0 10px;
}
#basket_goods_list .viewport {
  width: 315px;
  height: 159px;
  overflow: hidden;
  position: relative;
}
#basket_goods_list .overview {
  list-style: none;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
}
#basket_goods_list .scrollbar {
  background: transparent url(/img/sroll_img/bg-scrollbar-track-y.png) no-repeat 50% 0;
  position: relative;
  background-position: 50% 0;
  float: right;
  width: 15px;
}
#basket_goods_list .track {
  background: transparent url(/img/sroll_img/bg-scrollbar-trackend-y.png) no-repeat 50% 100%;
  height: 100%;
  width: 13px;
  position: relative;
  padding: 0 1px;
}
#basket_goods_list .thumb {
  background: transparent url(/img/sroll_img/bg-scrollbar-thumb-y.png) no-repeat 50% 100%;
  height: 20px;
  width: 25px;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: -5px;
}
#basket_goods_list .thumb .end {
  background: transparent url(/img/sroll_img/bg-scrollbar-thumb-y.png) no-repeat 50% 0;
  overflow: hidden;
  height: 5px;
  width: 25px;
}
#basket_goods_list .disable {
  display: none;
}
.mebel_top_basket_item {
  border-bottom: 1px solid #d4b41f;
  overflow: hidden;
  width: 320px;
  height: 53px;
}
.last {
  border-bottom: 0 none;
}
.mebel_top_basket_item_column {
  float: left;
  margin-top: 8px;
  width: 185px;
  font-family: Times New Roman;
  font-size: 14px;
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
}
.mebel_top_basket_item_name {
  font-size: 16px;
  text-transform: uppercase;
  font-family: Cuprum;
}
.mebel_top_basket_item_type {
  font-family: Times New Roman;
  font-style: italic;
  font-size: 14px;
}
.mebel_top_basket_item_price {
  color: #fff;
  float: left;
  font-size: 16px;
  padding-top: 17px;
  text-align: right;
  text-transform: uppercase;
  width: 110px;
  font-family: Cuprum, Arial, sans-serif;
}
.mebel_top_basket_item_close {
  background: url(/img/close.png) no-repeat scroll 50% 50% transparent;
  display: block;
  float: right;
  height: 25px;
  width: 25px;
  margin-top: 13px;
  cursor: pointer;
}
#top_top_line_inner {
  background: none repeat scroll 0 0 #fff;
  height: 100px;
  overflow: hidden;
}
#top_top_text {
  background: transparent url(/img/top_car.png) no-repeat scroll 370px 50%;
  margin: 0 auto;
  text-align: left;
  width: 957px;
  height: 100%;
  position: relative;
  font-family: Cuprum;
}
#delivery_first {
  font-size: 30px;
  float: left;
  margin: 30px 0 0 60px;
}
#delivery_second {
  font-size: 14px;
  margin-top: -2px;
}
#delivery_more {
  width: 188px;
  height: 29px;
  background: transparent url(/img/button_yellow.png) no-repeat scroll 0 0;
  text-align: center;
  font-size: 18px;
  display: block;
  float: left;
  margin: 28px 0 0 230px;
  text-transform: uppercase;
  text-decoration: none;
  padding-top: 8px;
}
.oblique_lines {
  position: absolute;
  background: transparent url(/img/oblique.png) no-repeat scroll 0 0;
  width: 390px;
  height: 100px;
  top: 0;
}
#top_top_left {
  left: -340px;
}
#top_top_right {
  right: -340px;
}
#slider {
  height: 600px;
  width: 100%;
  // background-color: #d4d4d4;
  background-color: #f3f3f3;

  @media only screen and (max-width: 450px) {
    display: none;
  }
}
// #top_line_opacity {
//   height: 100px;
//   opacity: 0.7;
//   background: #000;
//   z-index: 8;
//   left: -21.5px;
//   position: absolute;
//   width: 1000px;
//   top: 44px;
//   display: none;
// }

#mainpage_content {
  position: relative;
}
#shop_in_social {
  background: transparent url(/img/social_cube_dog.png) no-repeat scroll 0 0;
  width: 202px;
  font-family: Cuprum;
  font-size: 22px;
  text-transform: uppercase;
  height: 360px;
  margin: 0;
  position: absolute;
  right: 15px;
  top: 0;
}
#shop_in_social p {
  margin: 17px 19px 15px;
}
#shop_in_social a {
  text-decoration: none;
  display: block;
  background: transparent url(/img/social_big.png) no-repeat scroll 0 0;
  width: 164px;
  height: 48px;
  margin: 5px auto 10px;
}
#shop_in_social #big_odnkl {
  background-position: 0 -48px;
}
#wowslider-container1 .ws-title div div.slide_info {
  @extend .z-depth-1;
  background: #fff;
  width: 275px;
  padding: 0;
  margin: 0;
  border-radius: 8px;
  overflow: hidden;
}
#wowslider-container1 .ws-title div p.slide_name {
  padding: 15px;
  line-height: normal;
  margin: 0;
}
#wowslider-container1 .ws-title div p.slide_name a {
  font-family: Cuprum;
  font-size: 28px;
  color: #d7af01;
  text-transform: uppercase;
  text-decoration: underline;
}
#wowslider-container1 .ws-title div p.slide_name a:hover {
  text-decoration: none;
}
#wowslider-container1 .ws-title div p.slide_text {
  margin: 0 15px 20px;
  font-family: Cuprum;
  font-size: 17px;
}
#wowslider-container1 .ws-title div p.slide_more {
  background: var(--color-secondary);
  margin: 0;
  padding: 10px 0 7px;
}
#wowslider-container1 .ws-title div p.slide_more a {
  display: block;
  background: transparent url(/img/button.png) no-repeat scroll 0 0;
  font-family: Cuprum, Arial, sans-serif;
  font-size: 18px;
  margin: 0 auto;
  overflow: hidden;
  padding: 7px 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 187px;
}
#list_offerts {
  float: left;
}
#link_offerts {
  margin: 6px 0 0 15px;
}
#link_offerts a {
  font-size: 30px;
  font-family: Cuprum;
  color: #000;
  border-bottom: 1px dashed #d7af01;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0 15px 0 0;
}
#link_offerts a:hover {
  border: 0;
}
#link_offerts a.active {
  color: #fff;
  border: 0;
  padding: 7px 5px;
  background: #fdcf09;
  border-radius: 5px;
}
#content_offerts_wrapper {
  overflow: hidden;
  width: 726px;
  height: 265px;
}
#content_offerts {
  position: relative;
}
#content_offerts .slide_offert {
  position: absolute;
  left: 726px;
  top: 0;
  width: 726px;
  z-index: 2;
}
#content_offerts .active_offert {
  left: 0;
  z-index: 1;
}
.s {
  text-decoration: line-through;
}
.gray {
  color: gray;
}
// .puz_shop_form {
//   overflow: hidden;
// }
// .puz_form_tml .puz_form_item {
//   margin: 0 0 10px;
//   overflow: hidden;
//   text-align: left;
// }
// .puz_form_tml label {
//   display: block;
//   font: normal 700 15px Cuprum, Arial, sans-serif !important;
//   margin: 0 0 0.4em;
// }
// .puz_form_text input {
//   margin: 0;
//   width: 300px;
// }
// .puz_form_textarea textarea,
// .puz_form_text input {
//   display: block;
//   font: 12px/1.2 Cuprum, Arial, sans-serif;
// }
// .puz_form_textarea textarea {
//   height: 100px;
//   width: 298px;
// }
// .puz_form_radio input {
//   margin: 0 10px;
//   overflow: hidden;
//   text-align: left;
//   display: inline;
// }
// .puz_form_tml .puz_form_notice {
//   color: #c00;
//   font-size: 11px;
// }
// .puz_form_tml .puz_form_submit {
//   margin: 0.8px 0;
//   padding: 0;
// }
// .puz_form_tml .puz_form_submit input {
//   font: 14px/1.2 Cuprum, Arial, sans-serif;
//   text-transform: uppercase;
// }

.ajax_windox .puz_error_form,
.ajax_windox .puz_ok_form {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 21px;
}
.puz_user_basket {
  margin: 15px 0 30px;
}
.puz_user_basket table {
  width: 100%;
}
.puz_user_basket table,
.puz_user_basket td,
.puz_user_basket th {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  font-size: 100%;
}
.puz_user_basket table {
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  margin-bottom: 3px;
}
.puz_user_basket th {
  padding: 5px;
  font-size: 12px;
  font-weight: 400;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: #fdfdfd;
  color: #444;
  text-align: center;
}
.puz_user_basket .puz_name {
  width: 100%;
}
.puz_user_basket .puz_name .puz_title {
  font-size: 13px;
  font-weight: 700;
  height: 23px;
  overflow: hidden;
}
.puz_user_basket .puz_name .puz_title a {
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  font-family: Times New Roman;
  color: #666;
}
.puz_user_basket .puz_name .puz_title .puz_top_name {
  text-transform: uppercase;
  font-family: Cuprum;
  color: #000;
  font-style: normal;
}
.puz_user_basket .puz_name .puz_description {
  font-size: 12px;
}
.puz_user_basket .puz_name .puz_description .puz_params {
  font-size: 13px;
  float: left;
  font-family: Cuprum;
  margin: 9px 0 0;
}
.puz_user_basket .puz_name .puz_description .puz_colors {
  float: left;
  margin: 3px 0 0 30px;
}
.puz_user_basket .puz_name .puz_description .puz_colors .item_color {
  float: left;
  margin: 0 5px 0 0;
  border: 2px solid #000;
  width: 22px;
  height: 22px;
}
.puz_user_basket .puz_name .puz_description .puz_colors .selected {
  border: 2px solid #fdcf09;
}
.puz_user_basket .puz_name .puz_description .puz_colors .puz_color_name {
  clear: both;
  float: left;
}
.puz_user_basket .puz_name .puz_description .puz_colors .item_color img {
  width: 22px;
  height: 22px;
}
.puz_user_basket .puz_name .puz_description .puz_params .params_1 {
  color: #636363;
}
.puz_user_basket .puz_name .puz_description .puz_params .params_2 {
  color: #000;
}
.puz_user_basket .puz_name .puz_description .specs_list .spec_item {
  margin-right: 4px;
}
.puz_user_basket .puz_name .puz_description .specs_list .spec_item .value {
  font-weight: 700;
}
.puz_user_basket td {
  font-size: 12px;
  padding: 5px 7px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  vertical-align: top;
}
.puz_user_basket .puz_image {
  padding: 3px 5px;
}
.puz_user_basket .puz_image,
.puz_user_basket .puz_number,
.puz_user_basket .puz_wish_pos {
  text-align: center;
  vertical-align: middle;
}
.puz_user_basket .puz_number {
  width: 1%;
  padding: 0 1px;
}
.puz_user_basket .puz_number .puz_field table,
.puz_user_basket .puz_number .puz_field td {
  border: 0;
  padding: 0;
  vertical-align: middle;
}
.puz_user_basket .puz_number .puz_field input {
  width: 30px;
  margin: 0;
  text-align: center;
}
.puz_user_basket .puz_number .puz_field a {
  color: #e70000;
  display: block;
  text-align: center;
  font: 700 16px/1.3 Arial, Tahoma, sans-serif;
  text-decoration: none !important;
  width: 17px;
  height: 20px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.puz_user_basket .puz_delete_pos,
.puz_user_basket .puz_price_field {
  width: 1%;
  vertical-align: middle;
  text-align: center;
}
.puz_user_basket .puz_delete_pos {
  padding: 1px 7px;
  white-space: nowrap;
}
.puz_user_basket .puz_bottom .totals {
  font-size: 19px;
  text-align: right;
  background: #fdfdfd;
  color: #444;
}
.puz_user_basket .puz_bottom .totals .sum {
  font-weight: 700;
}
.puz_user_basket .puz_itog {
  margin: 1em 0;
  text-align: right;
  font-size: 18px;
}
.puz_user_basket .puz_itog strong {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}
.puz_basket_loader {
  background: url(/img/loader-small.gif) no-repeat scroll 50% 50% #fff;
  font-size: 13px;
}
#review {
  background: transparent url(/img/review_bg.png) repeat-x scroll 0 0;
  overflow: hidden;
  text-align: center;
  margin: 0;
}
#review h3 {
  text-transform: uppercase;
  font-family: Cuprum;
  font-size: 26px;
  margin: 10px 0 2px;
}
#review_content {
  overflow: hidden;
  margin: 0 auto 35px;
  height: 260px;
  width: 957px;
}
#review_content_inner {
  display: inline-block;
  margin: 0 auto;
}
#review_content .review {
  float: left;
  width: 180px;
  overflow: hidden;
  text-align: left;
  margin: 0 0 0 10px;
}
#review_content .review:first-child {
  margin-left: 0;
}
#review_content .review_text {
  font-size: 13px;
  font-family: Arial, sans-serif;
  margin: 0 0 0.8em;
}
#review_content .review_sender {
  color: gray;
  font-family: Times New Roman, serif;
  font-size: 12px;
  font-style: italic;
}
a.y_button {
  background: transparent url(/img/button_yellow.png) no-repeat scroll 0 0;
  display: block;
  font-size: 18px;
  font-family: Cuprum;
  margin: 0 auto;
  text-align: center;
  line-height: 36px;

  text-decoration: none;
  text-transform: uppercase;
  width: 188px;
}
#in_social_like {
  max-width: 957px;
  margin: 24px auto;
  padding: 24px 0 0;
  text-align: center;
  border-top: 1px solid #ccc;
}
#in_social_inner {
  display: inline-block;
  margin: 0 auto;
}
#say_it {
  font: italic normal normal 13px Times New Roman, serif;
  color: grey;
  margin-top: 3px !important;
}
#in_social_inner div {
  float: left;
  clear: none !important;
  margin: 0 0 0 10px;
}
#vk_like {
  width: 80px !important;
}
#process_message {
  display: none;
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 1000;
}
#process_message .load {
  background: url(/img/ajax-loader-01.gif) no-repeat scroll 90% 50% #fff9b8;
}
#process_message .b-message {
  font-weight: 700;
  padding: 7px 40px 9px 15px;
}
#substrate {
  background-color: #000;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}
#ajax_conteiner {
  font-size: 1.2em;
  min-height: 100px;
  position: absolute;
  text-align: left;
  width: auto;
  z-index: 1002;
}
#ajax_conteiner input,
#ajax_conteiner textarea {
  box-sizing: border-box;
}
#ajax_conteiner .sector_over input,
#ajax_conteiner .sector_over textarea {
  min-width: 290px;
  border: 2px solid #fdcf09;
  width: 100%;
}
#ajax_conteiner .ajax_windox {
  background-color: #fff;
  margin: 0;
  padding: 0;
  position: relative;
}
#ajax_conteiner .warn_text {
  color: #b5b5b5;
  font-size: 11px;
  font-style: italic;
}
#ajax_conteiner .sector_over {
  margin-top: 10px;
}
#ajax_conteiner .sector_over:first-child {
  margin-top: 0;
}
#ajax_conteiner .sector_over label {
  display: block;
  margin: 0 0 3px;
  font-family: Times New Roman, serif;
  font-style: italic;
}
#ajax_conteiner .sector_over input {
  height: 30px;
  padding: 0 5px;
}
#ajax_conteiner .mark {
  color: #d7af01;
}
#ajax_conteiner .sector_over textarea {
  height: 110px;
  padding: 5px;
}
#ajax_conteiner .ajax_windox_header {
  height: 25px;
  right: -25px;
  position: absolute;
  top: 0;
  width: 25px;
  overflow: hidden;
  color: #fdcf09;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#ajax_conteiner .ajax_windox_header:hover {
  background: #ff0;
}
#ajax_conteiner .ajax_windox_close {
  color: inherit;
  cursor: pointer;
  font-size: 37px;
  left: 0;
  overflow: hidden;
  position: absolute;
  text-align: center;
  line-height: 25px;
  width: 26px;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#ajax_conteiner .ajax_windox_close:hover {
  color: #000;
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
}
#ajax_conteiner .form_top {
  padding: 10px 20px;
}
#ajax_conteiner .form_bottom {
  background: #fdcf09;
  padding: 10px 20px;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
#ajax_conteiner .form_bottom:hover {
  background: #ff0;
}
#ajax_conteiner h2 {
  margin: 0;
  padding: 10px 20px 0;
  font: normal normal normal 26px Cuprum, Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
}
#ajax_conteiner #captcha_img {
  float: left;
  height: 60px;
  width: 120px;
}
#ajax_conteiner #captcha_text {
  float: right;
}
#ajax_conteiner #catcha_block {
  margin-bottom: 10px;
}
#ajax_conteiner #catcha_block p {
  margin: 0 0 10px;
}
#ajax_conteiner #catcha_block .separator {
  font-size: 30px;
  left: 50%;
  line-height: 56px;
  margin-left: -11px;
  position: absolute;
  color: #404040;
}
#ajax_conteiner #ajax_submit_button {
  background: url(/img/button.png) no-repeat scroll 0 0 transparent;
  cursor: pointer;
  display: block;
  font-family: Cuprum, Arial, sans-serif;
  font-size: 18px;
  height: 36px;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 187px;
  border: 0;
}
#ajax_conteiner .ajax_submit {
  margin: 0;
}
#ajax_conteiner #ajax_captcha {
  font-family: Cuprum;
  font-size: 32px;
  height: 60px;
  padding: 0 5px;
  text-align: center;
  width: 120px;
  text-transform: uppercase;
}
#ajax_conteiner .puz_error_form {
  width: 267px;
}
.specials_goods {
  h3 {
    font-size: 30px;
    line-height: 1;
    font-weight: 400;
    margin: 1.5rem 0.75rem;
    text-align: center;
    a {
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
      color: #484848;
      font-family: Cuprum;
      text-decoration: underline;
      
      &:hover {
        text-decoration: none;
      }
      // font-family: Cuprum;
      // font-size: 30px;
      // margin: 0 15px 0 0;
      // text-transform: uppercase;
      // background: none repeat scroll 0 0 #fdcf09;
      // border: 0 none;
      // border-radius: 5px;
      // color: #fff;
      // padding: 7px 5px;
    }
  }
}
.specials_goods .goodItem {
  margin: 0 12px 12px 0;
}
.special_newbie h3,
.special_sales h3,
.special_salesleader h3 {
  // margin-top: 12px;
}
.samecategory .goodsList {
  margin: 0;
}
.samecategory h3 {
  margin-top: 20px;
}
.samecategory .goodItem {
  margin: 0 15px 0 0;
}
.puz_photoalbums_foto_box {
  margin: 0 0 0 -12px;
  overflow: hidden;
}
.puz_photoalbums_foto_box .puz_img_item {
  display: inline-block;
  margin: 0 0 12px 12px;
  overflow: hidden;
  vertical-align: top;
  width: 200px;
}
.puz_photoalbums_foto_box .puz_img_item .puz_img_pre {
  border: 1px solid #ccc;
  height: 140px;
  margin: 0;
  overflow: hidden;
  width: 198px;
}
.puz_photoalbums_foto_box .puz_img_item .puz_img_name {
  background-color: #eee;
  font-size: 12px;
  font-weight: 400;
  height: 21px;
  line-height: 20px;
  overflow: hidden;
  padding: 0 7px;
  text-align: center;
  white-space: nowrap;
}
.puz_photoalbums_foto_box p {
  margin-left: 12px;
}
.puz_photoalbums_foto_box .puz_img_item .puz_img_pre a {
  display: block;
  height: 138px;
  margin: 1px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  width: 196px;
}
.puz_photoalbums_foto_box .puz_img_item .puz_img_pre img {
  position: relative;
  top: 17px;
}
#puz_substrate {
  background-color: #000;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
}
#puz_view_conteiner {
  background: none repeat scroll 0 0 #fff;
  border: 5px solid #525252;
  left: 50%;
  margin-left: -75px;
  margin-top: -75px;
  padding: 15px;
  position: absolute;
  z-index: 19;
}
#puz_inside_view_conteiner {
  background: url(/puz-img/loader.gif) no-repeat scroll 50% 50% transparent;
  height: 150px;
  overflow: hidden;
  width: 150px;
}
#puz_inside_view_conteiner img {
  display: block;
  position: relative;
  z-index: 20;
}
#puz_foto_description {
  background-color: #525252;
  display: none;
  margin-top: 5px;
  padding: 7px 10px;
}
#puz_foto_description,
#puz_foto_description p {
  color: #fff;
  font: 12px/1.1 Arial, sans-serif;
  text-align: left;
}
#puz_foto_description p {
  margin: 0;
}
#puz_foto_button_next2 {
  background: url(/puz-img/left.png) no-repeat scroll 0 -10000px transparent;
  display: block;
  height: 100%;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  z-index: 21;
}
#puz_foto_button_next2 span {
  background: url(/puz-img/right.png) no-repeat scroll 0 0 transparent;
  display: none;
  height: 116px;
  margin: -58px 0 0;
  opacity: 0.3;
  position: absolute;
  right: 10%;
  top: 50%;
  width: 80px;
}
#puz_foto_button_before2 {
  background: url(/puz-img/left.png) no-repeat scroll 0 -10000px transparent;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 50%;
  z-index: 21;
}
#puz_foto_button_before2 span {
  background: url(/puz-img/left.png) no-repeat scroll 0 0 transparent;
  display: none;
  height: 116px;
  left: 10%;
  margin: -58px 0 0;
  opacity: 0.3;
  position: absolute;
  top: 50%;
  width: 80px;
}
#ufnav_left,
#ufnav_right {
  position: absolute;
}
#ufnav_left {
  left: -37px;
  top: 57px;
}
#ufnav_right {
  right: -37px;
  top: 15px;
}
.puz_foto_button a {
  background: url(/puz-img/puz_foto_buttons.png) no-repeat scroll 0 0 #fff;
  display: block;
  height: 26px;
  margin: 0 0 8px !important;
  overflow: hidden;
  text-decoration: none;
  text-indent: -1000px;
  width: 26px;
}
#puz_foto_button_before {
  background-position: 0 0;
}
#puz_foto_button_brightness {
  background-position: 0 -78px;
}
#puz_foto_button_brightness:hover {
  background-position: -26px -78px;
}
#puz_foto_button_close {
  background-position: 0 -52px;
}
#puz_foto_button_close:hover {
  background-position: -26px -52px;
}
#puz_foto_button_play {
  background-position: 0 -104px;
  display: none;
}
#puz_foto_button_next {
  background-position: 0 -26px;
}
#puz_foto_button_next:hover {
  background-position: -26px -26px;
}
#puz_foto_button_before:hover {
  background-position: -26px 0;
}
#puz_foto_button_before2:hover span,
#puz_foto_button_next2:hover span {
  display: block;
}
#map {
  position: absolute;
  top: 270px;
}
a.button_yellow_arr {
  font-family: Cuprum, Arial, sans-serif;
  background: transparent url(/img/button_yellow_arr.png) no-repeat scroll 0 0;
  width: 128px;
  height: 38px;
  overflow: hidden;
  font-size: 18px;
  text-transform: uppercase;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.puz_search_form {
  font-size: 20px;
  margin-bottom: 30px;
  width: 100%;
}
.puz_search_form .puz_input_name {
  width: 79%;
}
.puz_search_form .puz_submit {
  float: right;
  width: 19%;
}
.puz_pagenator {
  clear: both;
  font-size: 12px;
  margin: 1em 0 0;
}
.puz_pagenator .puz_page_count {
  font-weight: 700;
  margin: 0 0 5px;
}
code,
pre {
  border: none;
  background: none repeat scroll 0 0 #fffef4;
  border-color: #9a9a9a #9a9a9a #9a9a9a #33353a;
  border-image: none;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 1px 1px 4px;
  padding-left: 32px;
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 10px;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  font-family: Monaco, Menlo, Consolas, Courier New, monospace;
  text-align: left;
}
#region_select_big {
  overflow: hidden;
  padding-left: 20px;
  width: 810px;
}
#region_select_big a {
  display: block;
  position: relative;
  float: left;
  width: 250px;
  height: 250px;
  margin: 0 20px 20px 0;
}
#region_select_big a.selected,
#region_select_big a:hover {
  background: #fdcf09;
}
#region_select_big span.blazon {
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  background: url(/img/regions_big.png) no-repeat transparent center 0;
  display: block;
}
#region_select_big span.reg_hmao {
  height: 169px;
  margin-top: -85px;
}
#region_select_big span.reg_kurgan {
  background-position: center -169px;
  height: 156px;
  margin-top: -78px;
}
#region_select_big span.reg_perm {
  background-position: center -325px;
  height: 182px;
  margin-top: -91px;
}
#region_select_big span.reg_svrd {
  background-position: center -507px;
  height: 142px;
  margin-top: -71px;
}
#region_select_big span.reg_tumen {
  background-position: center -649px;
  height: 153px;
  margin-top: -76px;
}
#region_select_big span.reg_chel {
  background-position: center -802px;
  height: 154px;
  margin-top: -77px;
}
#region_select_big span.reg_name {
  text-align: center;
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  color: #d7af01;
  font-family: Cuprum;
  font-size: 22px;
  text-decoration: none;
}
#region_select_big a.selected span.reg_name,
#region_select_big a:hover span.reg_name {
  color: #fff;
  text-decoration: none;
}
#oplata {
  overflow: hidden;
}
.oplata_type {
  float: left;
  width: 319px;
  margin-bottom: 20px;
}
.oplata_title {
  font-family: Cuprum;
  font-size: 24px;
}
.oplata_info,
.oplata_title {
  margin: 5px 20px;
}
.oplata_ico {
  width: 144px;
  height: 146px;
  background: transparent url(/img/oplata_sprite.png) no-repeat scroll 0 0;
  margin: 0 auto;
}
.oplata_ico1 {
  background-position: 0 0;
}
.oplata_ico2 {
  background-position: -144px 0;
}
.oplata_ico3 {
  background-position: -288px 0;
}
.oplata_ico4 {
  background-position: -432px 0;
}
.oplata_ico5 {
  background-position: -576px 0;
}
.oplata_ico6 {
  background-position: -720px 0;
}

.delivery {
  // background: transparent url(/img/delivery_bg.jpg) no-repeat scroll 0 0;
  background: transparent url(/img/delivery_bg-removebg-preview.gif) no-repeat scroll 0 0;
}
.delivery_blank {
  float: right;
  background: #fff url(/img/delivery_blank.png) no-repeat scroll 0 bottom;
  width: 480px;
  margin-bottom: 50px;
}
.delivery_blank h3 {
  font: normal normal normal 24px Cuprum, Arial, sans-serif;
}
.delivery_blank p {
  color: #6f6f6f;
  font: normal normal normal 14px Cuprum, Arial, sans-serif;
  clear: both;
  float: none;
}
.delimiter_grey {
  background: #deddda;
  height: 1px;
}
.delivery_blank ul {
  list-style: none;
  list-style-position: outside;
  overflow: hidden;
}
.delivery_blank ul li {
  color: #6f6f6f;
  font: normal normal normal 14px Cuprum, Arial, sans-serif;
  background: transparent url(/img/delivery_li.gif) no-repeat scroll 0 50%;
  float: left;
  width: 190px;
  margin: 5px 10px 5px 0;
  padding-left: 10px;
}
.delivery_blank div,
.delivery_blank h3,
.delivery_blank p,
.delivery_blank ul {
  margin-left: 20px;
  margin-right: 20px;
}
.delivery_ico {
  clear: both;
  float: none;
  overflow: hidden;
  background: transparent url(/img/delivery_delimiter.png) no-repeat scroll 48% 100%;
  padding-bottom: 45px;
  text-align: center;
  margin: 0 auto;
}
.di {
  width: 146px;
  height: 146px;
  background: transparent url(/img/delivery_sprite.png) no-repeat scroll 0 0;
  border-radius: 50%;
  margin: 0 auto;
}
.di_1 {
  background-position: 0 0;
}
.di_2 {
  background-position: -146px 0;
}
.di_3 {
  background-position: -292px 0;
}
.di_4 {
  background-position: -438px 0;
}
.delivery_type {
  margin: 0;
  width: 24%;
  padding: 0 10px;
  display: inline-block;
  box-sizing: border-box;
}
.delivery_type h2 {
  font: normal normal normal 24px Cuprum, Arial, sans-serif;
}
.up_title {
  text-transform: uppercase;
  font: normal normal normal 36px Cuprum, Arial, sans-serif;
  text-align: center;
  margin: 20px 0 15px;
}
.sub_title {
  font: normal normal normal 18px Cuprum, Arial, sans-serif;
  text-align: center;
  margin: 0.8em 0;
}
.yellow_blank {
  background: #fdcf09;
  padding-bottom: 25px;
}
.yellow_blank ul {
  list-style: none;
  overflow: hidden;
}
.yellow_blank ul li {
  float: left;
  width: 200px;
  font: normal normal normal 15px Cuprum, Arial, sans-serif;
}
.yellow_blank ul li p {
  font: normal normal normal 36px Cuprum, Arial, sans-serif;
}

#madeIn {
  position: relative;
  display: block;
  z-index: 14;
  width: 28px;
  height: 28px;
  float: left;
  margin: 25px 0 0;
  border-radius: 15px;
  background: transparent url(http://redboo.ru/img/redboo28.png) no-repeat 50% 50%;
  background-size: contain;
}
#madeIn span {
  position: absolute;
  font-size: 2.6em;
  color: #fff;
  text-shadow: 2px 2px 2px red;
  top: 0;
  left: 100%;
  height: 100%;
  line-height: 28px;
  padding-left: 10px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}
#madeIn:hover span {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
#way {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  -webkit-transition: all 0s step-start 5.3s;
  transition: all 0s step-start 5.3s;
}
#turbo,
#way {
  height: 150px;
}
#turbo {
  background: transparent url(/img/turbo.png) no-repeat scroll center center;
  width: 200px;
  position: absolute;
  left: 100%;
  -webkit-transition: all 3.6s ease-out 3s;
  transition: all 3.6s ease-out 3s;
}
#way.move {
  height: 0;
}
.move #turbo {
  left: -100%;
}
.hata {
  width: 100%;
  overflow: hidden;
  margin-top: 15px;
  position: relative;
  min-height: 362px;
}
.hata em {
  font-size: 20px;
  font-style: normal;
}
.hata ul {
  position: relative;
  width: 30%;
  float: left;
  padding: 10px 0 0 1%;
  margin: 0;
}
.hata ul.col-right {
  float: right;
}
.hata ul ul {
  width: auto;
  float: none;
  margin: 0 0 0 20px;
}
.hata ul li {
  margin: 0;
  list-style: none;
  padding: 0 0 20px;
}
.hata ul li li {
  font-size: 14px;
  padding: 0;
}
.hata li a {
  text-transform: uppercase;
  text-decoration: none;
  line-height: 32px;
  font-size: 18px;
  font-family: Cuprum;
}
.hata li a:hover,
.hata li li a {
  text-decoration: underline;
}
.hata li li a {
  font-weight: 400;
  text-transform: capitalize;
  line-height: 25px;
  font-size: 16px;
}
.hata li li a:hover {
  text-decoration: none;
}
.hata_inner {
  position: absolute;
  left: 0;
  background-repeat: no-repeat;
  top: 0;
  width: 100%;
  background-color: #ececec;
  height: 100%;
  background-position: 50% top;
}
.hata_1 {
  background-image: url(/img/3d_1.jpg);
}
.hata_2 {
  background-image: url(/img/3d_2.jpg);
}
.overlay-light {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  opacity: 0.7;
  z-index: 6;
}
a.button-large-yellow {
  background: linear-gradient(-5deg, #fdaf09 27%, #fdcf09 73%);
  border-radius: 24px;
  box-shadow: 0 21px 46px 0 rgba(106, 51, 0, 0.29);
  width: 376px;
  height: 133px;
  display: none;
  padding-top: 1px;
  text-decoration: none;
  z-index: 7;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -66px 0 0 -188px;
}
a.button-large-yellow span {
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.6) 0, hsla(0, 0%, 100%, 0.1) 100%);
  border-radius: 24px;
  line-height: 133px;
  text-align: center;
  color: #000;
  font-size: 48px;
  font-family: Cuprum;
  text-decoration: none;
  width: 374px;
  height: 110px;
  margin: 0 auto;
  display: block;
}
a.button-large-yellow:hover {
  box-shadow: 0 21px 46px 0 #fdc127;
}
a.button-large-yellow:focus span {
  background: 0 0;
}
.hata_inner:hover > .overlay-light,
.hata_inner:hover > a.button-large-yellow {
  display: block;
}
@media (max-width: 860px) {
  #select_town {
    width: 0;
    overflow: hidden;
  }
  .status_order span {
    display: none;
  }
  #search button {
    width: 26px;
  }
  #search input {
    margin: 0 0 0 26px;
    padding: 0 0 0 14px;
  }
}
.button__white {
  cursor: pointer;
  width: 100%;
  max-width: 187px;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
  font-family: Cuprum, Arial, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 7px 10px;
  text-decoration: none !important;
  display: inline-block;
  color: #000000;
  background-color: #f4f5f5;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#f4f5f5), to(#dfdddd));
  background-image: -webkit-linear-gradient(top, #f4f5f5, #dfdddd);
  background-image: -moz-linear-gradient(top, #f4f5f5, #dfdddd);
  background-image: -ms-linear-gradient(top, #f4f5f5, #dfdddd);
  background-image: -o-linear-gradient(top, #f4f5f5, #dfdddd);
  background-image: linear-gradient(to bottom, #f4f5f5, #dfdddd);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#f4f5f5, endColorstr=#dfdddd);
}
.button__white:hover {
  background-color: #d9dddd;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#d9dddd), to(#c6c3c3));
  background-image: -webkit-linear-gradient(top, #d9dddd, #c6c3c3);
  background-image: -moz-linear-gradient(top, #d9dddd, #c6c3c3);
  background-image: -ms-linear-gradient(top, #d9dddd, #c6c3c3);
  background-image: -o-linear-gradient(top, #d9dddd, #c6c3c3);
  background-image: linear-gradient(to bottom, #d9dddd, #c6c3c3);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#d9dddd, endColorstr=#c6c3c3);
}
.button__orange {
  border: 0px solid #ffad41;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-family: arial, helvetica, sans-serif;
  padding: 10px 10px 10px 10px;
  text-decoration: none;
  display: inline-block;
  color: #000000;
  background-color: #ffc579;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffc579), to(#fb9d23));
  background-image: -webkit-linear-gradient(top, #ffc579, #fb9d23);
  background-image: -moz-linear-gradient(top, #ffc579, #fb9d23);
  background-image: -ms-linear-gradient(top, #ffc579, #fb9d23);
  background-image: -o-linear-gradient(top, #ffc579, #fb9d23);
  background-image: linear-gradient(to bottom, #ffc579, #fb9d23);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#ffc579, endColorstr=#fb9d23);
}
.button__orange:hover {
  border: 0px solid #ff9913;
  background-color: #ffaf46;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffaf46), to(#e78404));
  background-image: -webkit-linear-gradient(top, #ffaf46, #e78404);
  background-image: -moz-linear-gradient(top, #ffaf46, #e78404);
  background-image: -ms-linear-gradient(top, #ffaf46, #e78404);
  background-image: -o-linear-gradient(top, #ffaf46, #e78404);
  background-image: linear-gradient(to bottom, #ffaf46, #e78404);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#ffaf46, endColorstr=#e78404);
}
.button__border-orange {
  border: 2px solid #e78404;
}
