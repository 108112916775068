.review {
  -webkit-box-shadow: inset 0 20px 12px -20px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 20px 12px -20px rgba(0, 0, 0, 0.5);
  overflow: auto;
  background: #fff;
  margin-top: 1.5rem;

  &__header {
    font-family: "Cuprum";
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    font-weight: inherit;
    margin: 1.5rem 0.75rem;
  }
  &__inner {
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: 24px;
  }
  &-item__wrapper {
    display: table;
    white-space: nowrap;
    margin: 0 auto;
  }
  &-item {
    display: table-cell;
    white-space: normal;
    width: 250px;
    min-width: 250px;
    padding: 0 6px;
    font-size: 0.9rem;
    text-align: left;
  }
  &-item__author {
    color: gray;
    font-family: serif;
    font-size: 0.8em;
    font-style: italic;
  }
}
