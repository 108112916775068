#basket {
  background: #fdcf09 url(/img/bag.png) no-repeat scroll 24px 12px;
  height: 44px;
  position: relative;
}
#basket_inner {
  font-family: Cuprum, Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0 15px;
  color: #858585;
  line-height: 44px;
}
#bag {
  margin: 0 8px;
}
#basket_click {
  cursor: pointer;
  margin: 0 0 0 39px;
  background: transparent url(/img/arr_down.png) no-repeat scroll 100% 50%;
  white-space: nowrap;
}
#goods {
  text-decoration: underline;
  color: #000;
}
#puz_block_basket_totals,
#total,
#total_empty {
  margin: 0 16px 0 8px;
}

.puz_error_form {
  background-color: #feaeae;
  border: 1px solid #c00;
  border-radius: 8px;
  color: #000;
  margin: 0 0 1.5rem;
  padding: 5px 15px 3px;
  ol {
    margin: 0;
    li {
      color: #000;
      font-family: Cuprum, Arial, sans-serif;
      font-size: 15px;
      margin: 0 0 2px 15px;
      padding: 0;
    }
  }
}
.puz_ok_form {
  background-color: #b7feae;
  border: 2px solid #00cc30;
  color: #000;
  font-size: 13px;
  margin: 0 0 1em;
  padding: 5px 15px;
  b {
    font-size: 18px;
  }
}

.basket-btn {
  position: relative;

  &__notification {
    position: absolute;
    top: 10px;
    right: 7px;
    color: white;
    background-color: #c00;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    line-height: 18px;
    font-size: 10px;
    text-align: center;
    cursor: pointer;
    font-family: Roboto, Arial, sans-serif;
  }
}
