.fullscreen-bg {
  position: relative;
  // width: 100%;
  // height: calc(100vh - 44px);
  @media #{$small-and-down} {
    // styles for small screens and down
    display: none;
  }

  &__video {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    display: block;
    // height: 100%;
    // object-fit: cover;
  }
}
