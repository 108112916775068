.goods-list {
  $parent-selector: &;
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(var(--gutter-width) * -1);
  text-align: left;

  &__item {
    padding: 0 var(--gutter-width) var(--gutter-width) 0;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;

    @media only screen and (min-width: 401px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media only screen and (min-width: 651px) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    @media only screen and (min-width: 951px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  &__image {
    margin-bottom: var(--gutter-width);
    display: block;

    img {
      vertical-align: middle;
      object-fit: contain;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in;

      &:after {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
    }
  }

  &__inner {
    border-radius: var(--radius-goods-list__inner);
    background: #fff;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    height: 100%;
    overflow: hidden;
    // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    position: relative;
    padding: 8px 8px 0;
    // transition: 0.2s ease-in-out;
    border-bottom: 6px solid white;
    
    &:hover {
      @extend .z-depth-2;
      border-bottom: 6px solid darkorange;

      img {
        transform: scale(1.04);
      }
      // background: #fff8e1;

      @media #{$small-and-down} {
        transform: none;
      }
    }
  }

  &__thumb {
    max-width: 100%;
    padding-bottom: 100%;
    position: relative;
    margin: auto;
  }

  &__thumb-wrap {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__name {
    margin-top: calc(var(--gutter-width) / 2);

    a {
      text-decoration: none;
      cursor: pointer;

      :hover {
        text-decoration: underline;

        span {
          text-decoration: none;
        }
      }
    }

    h4 {
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
      margin: 0;
      font-family: "Cuprum";

      > p {
        color: #5f6368;
        margin: 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        > span {
          color: #222;
          font-weight: bold;
        }
      }

      &.ellipsis {
        overflow: hidden;
        max-height: 60px;
        line-height: 20px;

        &::before {
          content: "";
          float: left;
          width: 5px;
          max-height: 60px;
        }

        & > *:first-child {
          float: right;
          width: 100%;
          margin-left: -5px;
        }

        &:after {
          content: "\02026";
          box-sizing: content-box;
          float: right;
          position: relative;
          top: -25px;
          left: 100%;
          width: 3em;
          margin-left: -3em;
          padding-right: 5px;
          text-align: right;
          background-size: 100% 100%;
          /* 512x1 image, gradient for IE9. Transparent at 0% -> white at 50% -> white at 100%.*/
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAABCAMAAACfZeZEAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5TgsRjMZXhS30YrvDUP3Emow1YibnM9+ggOZxrBtpRRo94gxItwLOoX/vsHdA2yGgL8+TdKUK8VFufmHSGgAQWJNc9tk+rb5KMCA8aM0iwpWV6dwP9+fXuFerm3yMs0jDOysY8wr5FTldeoWKabgEJ8RATG+IeIdsn2NUqLjQ3OgBDumC3SbRMsVKsValZplydZpZpbJOQco2KdYeEe36BDAL8/vgHBfr2CvTyDu8R7esU6RcZ5ecc4+Af3iLcJSjZ1ivT0S/PMs3LNck4x8U7wz7Bv0G9RLtHuEq1TbJQr1OtVqqnWqRdoqBhnmSbZ5mXapRtcJGOc4t2eYiFfH9AS7qYlgAAARlJREFUKM9jqK9fEGS7VNrDI2+F/nyB1Z4Fa5UKN4TbbeLY7FW0Tatkp3jp7mj7vXzl+4yrDsYoVx+JYz7mXXNSp/a0RN25JMcLPP8umzRcTZW77tNyk63tdprzXdmO+2ZdD9MFe56Y9z3LUG96mcX02n/CW71JH6Qmf8px/cw77ZvVzB+BCj8D5vxhn/vXZh6D4uzf1rN+Cc347j79q/zUL25TPrJMfG/5LvuNZP8rixeZz/mf+vU+Vut+5NL5gPOeb/sd1dZbTs03hBuvmV5JuaRyMfk849nEM7qnEk6IHI8/qn049hB35QGHiv0yZXuMdkXtYC3ebrglcqvYxoj1muvC1nDlrzJYGbpcdHHIMo2FwYv+j3QAAOBSfkZYITwUAAAAAElFTkSuQmCC);
          background: -webkit-gradient(
            linear,
            left top,
            right top,
            from(rgba(255, 255, 255, 0)),
            to(white),
            color-stop(50%, white)
          );
          background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
          background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
          background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
          background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
        }
      }
    }
  }

  &__secondary {
    height: 42px;
  }

  &__price {
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }

  &__price-content {
    padding: var(--gutter-width) var(--gutter-width) .5rem;
    color: #222;
    font-size: 16px;
    font-weight: bold;
    min-height: 20px;
    // margin-right: 4px;
    line-height: 18px;
  }

  &__editlink {
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    display: none;
    height: 36px;
    // opacity: 0.9;
    opacity: 0.5;
    position: absolute;
    width: 36px;
    z-index: 1;
    text-align: center;
    top: 0.5rem;
    right: 0.5rem;
    transition: 0.3s;
    color: $off-black;

    &:hover {
      opacity: 1;
    }

    :hover > & {
      display: block;
    }

    > i {
      line-height: 36px;

      img.svg {
        vertical-align: middle;
      }
    }
  }

  &__label {
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 5;
    width: auto;
    transition: all 0.5s ease-out;

    span {
      display: block;
      font-family: Cuprum;
      font-size: 13px;
      padding: 3px 5px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
    }

    &-newbie {
      background: #ff0;
    }
    &-salesleader {
      background: var(--color-leader);
      color: #fff;
    }
    &-sales {
      background: var(--color-sale);
    }
  }
}
