.sameproducts {
  // background: #fff;
  padding-bottom: 0.75rem;

  &__carousel {
  }

  &__draggable {
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-drag: none;
    user-select: none;
  }

  &__scroller {
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    overflow: hidden;
    margin: 0 -0.75rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__list {
    // -ms-overflow-style: none;
    // -webkit-overflow-scrolling: touch;
    // align-items: stretch;
    // display: -ms-flexbox;
    // display: -webkit-flex;
    // display: flex;
    // flex-wrap: nowrap;
    // // padding: 12px 16px;
    // padding: 0.5rem 0.75rem 0.75rem;
    // position: relative;
    // overflow: hidden;
    // overflow-x: scroll;

    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    align-items: stretch;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: nowrap;
    padding: 0.75rem;
    position: relative;
  }

  &__item {
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: row;
    flex-shrink: 0;
    height: 76px;
    margin-right: 0.5rem;
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: 0.2s;
    width: 76px;

    // &:first-child {
    //   border-left: none;
    //   margin-left: 0;
    // }

    &-expand {
      width: 260px;
    }
  }

  &__item-wrap {
    display: flex;
    flex-shrink: 0;
    width: 260px;
  }

  &__title {
    font-family: Cuprum, Arial, sans-serif;
    overflow: hidden;
    font-size: 18px;
    text-transform: uppercase;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 0 0.75rem;
  }

  &__link {
    color: #000;
  }

  &__image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    display: inline-block;
    height: 68px;
    margin: 4px;
    width: 68px;
  }

  &__name {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #80868b;
    display: -webkit-box;
    font-weight: 500;
    height: 36px;
    letter-spacing: 0.25px;
    line-height: 18px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
  }

  &__info {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    margin: 8px 4px 0 0;
    padding-left: 8px;
    text-align: left;
    text-decoration: none !important;
    width: 169px;
    vertical-align: top;

    &:hover {
      text-decoration: underline !important;
    }
  }

  &__price {
    > span {
      color: #202124;
      font-size: 16px;
      font-weight: bold;
      margin-right: 4px;
    }
  }
}
.sameproducts_list .prebutton {
  float: left;
  padding-top: 10px;
  margin-right: 10px;
}
.sameproducts_list .sameproducts_list_items .sameproducts_item {
  float: left;
  margin-left: 25px;
  text-align: center;
  width: 92px;
}
.sameproducts_list .sameproducts_list_items .sameproducts_item:first-child {
  margin-left: 0;
}
.sameproducts_list .sameproducts_list_items .sameproducts_item_span,
.sameproducts_list .sameproducts_list_items .sameproducts_item a {
  display: table-cell;
  width: 82px;
  height: 48px;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  background: #fff;
}
.sameproducts_list .sameproducts_list_items .sameproducts_item_span,
.sameproducts_list .sameproducts_list_items .sameproducts_item a:hover {
  background: #fdcf09;
}
.sameproducts_list .sameproducts_list_items .sameproducts_item_title {
  font-family: Cuprum, Arial, sans-serif;
  font-size: 14px;
}
