.topmenu {
  // float: left;
  // margin-left: 0.5rem;

  & > li {
    margin-left: 0.5rem;

    &.active {
      background-color: unset;
    }
  }

  a {
    font-family: Cuprum, Arial, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
    white-space: nowrap;
    color: $navbar-font-color;
    border-radius: 4px;
    margin: 0;
    padding: 0 0.5rem;
    line-height: 32px;
    text-decoration: none;
    box-sizing: content-box;

    &:first-child {
      // margin-left: 0;
    }

    &:hover,
    &.active {
      // border: 2px solid #fdcf09;
      // padding: 3px 0.5rem;
      // border-radius: var(--goods-list__item-radius);
      // // background: #fff;
      // /* color: #fff; */
      // /* text-shadow: 0 -1px 0 rgba(194, 194, 194, 0.75); */
      // text-decoration: none;
      background-color: rgba(255, 255, 255, 0.15);
    }

    &:active,
    &:focus {
      background-color: rgba(255, 255, 255, 0.15);
      box-shadow: inset 0 0 0 2px white;
    }
  }

  &-main {
    a {
      // text-shadow: none;
      // color: #fff;
    }
  }
}
