.footer {
  padding: 0 0 2rem 0;
  // background: var(--color-primary);
  background-color: #f3f3f3;

  @media (min-width: 48rem) {
    padding: 2rem 0;
  }

  &__nav {
  }

  &__copyright {
    // font-size: 0.75rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  &__list--plain {
    list-style: none;
    // margin-left: 0;
    padding-left: 0;
  }

  &__list--horizontal {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
  }

  &__horizontal-list-item {
    display: inline-block;
  }

  &__horizontal-list-item--social {
    margin-bottom: 1rem;
    @media (min-width: 48rem) {
      margin-right: 0.5rem;
    }
  }

  &__social-link {
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 0.5rem 1rem;
    text-decoration: none !important;

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }

  &__svg-icon--social {
    width: 2rem;
    height: 2rem;
    vertical-align: top;
    margin-right: 0;

    @media (min-width: 48rem) {
      margin-right: 0.5rem;
    }
  }

  &__inner-item-text {
    display: none;
    @media (min-width: 48rem) {
      font-family: Cuprum;
      display: inline;
      color: #000;
    }
  }
}
