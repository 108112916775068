.status_order {
  font-family: Cuprum, Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  color: #8f8f8f;
  margin: 0;
  line-height: 44px;
  width: 110px;
  text-align: center;
  position: relative;

  a {
    color: #fdc501;
    display: block;
    text-decoration: underline;
    width: 100%;
    background: #f1f1f1;
    transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
      background: #fdcf09;
      color: #000;
    }
  }
}
