:root {
  --gutter-width: 0.75rem;
  --goods-list__item-radius: 8px;
  --radius-goods-list__inner: 8px;

// Colors
// ==========================================================================
  --color-main: #fdcf09;

  --color-newbie: #ff0;
  // --color-leader: #ff0000;
  --color-leader: #ff4e42;
  --color-sale: #0f0;

  --color-success: #76ff03;
  --color-error: #f44336;

  // --color-primary: #fdcf09;
  --color-primary: #4a4a4a;
  --color-primary-light: #767676;
  --color-primary-dark: #222222;

  // --color-secondary: #fdd009;
  --color-secondary: #fdcf09;
  --color-secondary-light: #ffff53;
  --color-secondary-dark: #c59f00;

  --color-accent: #1a73e8;
}
// ==========================================================================
// Materialize variables
// ==========================================================================
//
// Table of Contents:
//
//  1. Colors
//  2. Badges
//  3. Buttons
//  4. Cards
//  5. Carousel
//  6. Collapsible
//  7. Chips
//  8. Date + Time Picker
//  9. Dropdown
//  10. Forms
//  11. Global
//  12. Grid
//  13. Navigation Bar
//  14. Side Navigation
//  15. Photo Slider
//  16. Spinners | Loaders
//  17. Tabs
//  18. Tables
//  19. Toasts
//  20. Typography
//  21. Footer
//  22. Flow Text
//  23. Collections
//  24. Progress Bar

// 1. Colors
// ==========================================================================

// PRIMARY
// #fdd009

// COMPLEMENTARY
// #0936fd

// ANALOGOUS
// #fd5609
// #b0fd09

// TRIADIC
// #36fd09
// #09fdd0

// $primary-color: #fdd009 !default;
// $primary-color-light: #ffff53 !default;
// $primary-color-dark: #c59f00 !default;

$primary-color: #4c4c4c !default;
$primary-color-light: #767676 !default;
$primary-color-dark: #222222 !default;

$secondary-color: #fdd009 !default;
$secondary-color-light: #ffff53 !default;
$secondary-color-dark: #c59f00 !default;

// $secondary-color: #fafafa !default;
// $success-color: color("green", "base") !default;
// $error-color: color("red", "base") !default;
// $link-color: color("light-blue", "darken-1") !default;

$off-black: rgba(0, 0, 0, 0.87) !default;

// $success-color: #c9ff28 !default;
// $error-color: #ff5e28 !default;
// $success-color: #b0fd09 !default;
// $error-color: #fd5609 !default;
$success-color: #76ff03 !default;
$error-color: #f44336 !default;
$link-color: $off-black !default;

// $accent-color: #e5e534 !default;
$accent-color: #1a73e8 !default;

// 2. Badges
// ==========================================================================

$badge-bg-color: $secondary-color !default;
$badge-height: 22px !default;

// 3. Buttons
// ==========================================================================

// Shared styles
$button-border: none !default;
$button-background-focus: lighten($secondary-color, 4%) !default;
$button-font-size: 16px !default;
$button-font-family: Cuprum, sans-serif !default;
$button-icon-font-size: 1.3rem !default;
$button-height: 36px !default;
$button-padding: 0 16px !default;
$button-radius: 6px !default;

// Disabled styles
$button-disabled-background: #dfdfdf !default;
$button-disabled-color: #9f9f9f !default;

// Raised buttons
$button-raised-background: $primary-color !default;
$button-raised-background-hover: darken($button-raised-background, 5%) !default;
// $button-raised-color: #fff !default;
$button-raised-color: $off-black !default;

// Large buttons
$button-large-font-size: 15px !default;
$button-large-icon-font-size: 1.6rem !default;
$button-large-height: $button-height * 1.5 !default;
$button-floating-large-size: 56px !default;

// Small buttons
$button-small-font-size: 13px !default;
$button-small-icon-font-size: 1.2rem !default;
$button-small-height: $button-height * 0.9 !default;
$button-floating-small-size: $button-height * 0.9 !default;

// Flat buttons
$button-flat-color: $off-black !default;
$button-flat-disabled-color: lighten(#999, 10%) !default;

// Floating buttons
$button-floating-background: $primary-color-dark !default;
$button-floating-background-hover: $button-floating-background !default;
$button-floating-color: #fafafa !default;
$button-floating-size: 40px !default;
$button-floating-radius: 50% !default;

// 4. Cards
// ==========================================================================

$card-padding: 24px !default;
$card-bg-color: $secondary-color !default;
// $card-link-color: color("orange", "accent-2") !default;
$card-link-color: $primary-color-dark !default;
$card-link-color-light: lighten($card-link-color, 10%) !default;

// 5. Carousel
// ==========================================================================

$carousel-height: 400px !default;
$carousel-item-height: $carousel-height / 2 !default;
$carousel-item-width: $carousel-item-height !default;

// 6. Collapsible
// ==========================================================================

$collapsible-height: 3rem !default;
$collapsible-line-height: $collapsible-height !default;
$collapsible-header-color: #fff !default;
$collapsible-border-color: #ddd !default;

// 7. Chips
// ==========================================================================

$chip-bg-color: #e4e4e4 !default;
$chip-border-color: #9e9e9e !default;
$chip-selected-color: #26a69a !default;
$chip-margin: 5px !default;

// 8. Date + Time Picker
// ==========================================================================

$datepicker-display-font-size: 2.8rem;
$datepicker-calendar-header-color: #999;
$datepicker-weekday-color: rgba(0, 0, 0, 0.87) !default;
$datepicker-weekday-bg: darken($secondary-color, 7%) !default;
$datepicker-date-bg: $secondary-color !default;
$datepicker-year: rgba(255, 255, 255, 0.7) !default;
$datepicker-focus: rgba(0, 0, 0, 0.05) !default;
$datepicker-selected: $secondary-color !default;
$datepicker-selected-outfocus: desaturate(
  lighten($secondary-color, 35%),
  15%
) !default;
$datepicker-day-focus: transparentize(
  desaturate($secondary-color, 5%),
  0.75
) !default;
$datepicker-disabled-day-color: rgba(0, 0, 0, 0.3) !default;

$timepicker-clock-color: rgba(0, 0, 0, 0.87) !default;
$timepicker-clock-plate-bg: #eee !default;

// 9. Dropdown
// ==========================================================================

$dropdown-bg-color: #fff !default;
$dropdown-hover-bg-color: #eee !default;
// $dropdown-color: $secondary-color !default;
$dropdown-color: rgba(0, 0, 0, 0.87) !default;
$dropdown-item-height: 50px !default;

// 10. Forms
// ==========================================================================

// Text Inputs + Textarea
$input-height: 3rem !default;
$input-border-color: $off-black !default;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;
$input-error-color: $error-color !default;
$input-success-color: $primary-color-dark !default;
// $input-focus-color: $primary-color-dark !default;
$input-focus-color: $accent-color !default;
$input-font-size: 16px !default;
$input-margin-bottom: 8px;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 0 !default;
$label-font-size: 0.8rem !default;
$input-disabled-color: rgba(0, 0, 0, 0.42) !default;
$input-disabled-solid-color: #949494 !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$input-icon-size: 2rem;
$placeholder-text-color: lighten($off-black, 20%) !default;

// Radio Buttons
// $radio-fill-color: $primary-color-dark !default;
$radio-fill-color: $accent-color !default;
$radio-empty-color: #5a5a5a !default;
$radio-border: 2px solid $radio-fill-color !default;

// Range
$range-height: 14px !default;
$range-width: 14px !default;
$track-height: 3px !default;

// Select
$select-border: 1px solid #f2f2f2 !default;
$select-background: rgba(255, 255, 255, 0.9) !default;
$select-focus: 1px solid lighten($secondary-color, 47%) !default;
$select-option-hover: rgba(0, 0, 0, 0.08) !default;
$select-option-focus: rgba(0, 0, 0, 0.08) !default;
$select-option-selected: rgba(0, 0, 0, 0.03) !default;
$select-padding: 5px !default;
$select-radius: 2px !default;
$select-disabled-color: rgba(0, 0, 0, 0.3) !default;
$select-font-color: $off-black !default;

// Switches
$switch-bg-color: $primary-color-light !default;
$switch-checked-lever-bg: $primary-color-dark !default;
$switch-unchecked-bg: #f1f1f1 !default;
$switch-unchecked-lever-bg: rgba(0, 0, 0, 0.38) !default;
$switch-radius: 15px !default;

// 11. Global
// ==========================================================================

// Media Query Ranges
// $small-screen-up: 601px !default;
// $medium-screen-up: 993px !default;
// $large-screen-up: 1201px !default;
// $small-screen: 600px !default;
// $medium-screen: 992px !default;
// $large-screen: 1200px !default;

$extra-small-screen: 480px !default;
$extra-small-screen-up: 481px !default;
$small-screen: 768px !default;
$small-screen-up: 769px !default;
$medium-screen: 992px !default;
$medium-screen-up: 993px !default;
$large-screen: 1200px !default;
$large-screen-up: 1201px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$small-and-up: "only screen and (min-width : #{$extra-small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$extra-small-and-down: "only screen and (max-width : #{$extra-small-screen})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;
$small-only: "only screen and (min-width : #{$extra-small-screen-up}) and (max-width : #{$small-screen})" !default;

// 12. Grid
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width * 2)/3 !default;

// 13. Navigation Bar
// ==========================================================================

$navbar-height: 64px !default;
$navbar-line-height: $navbar-height !default;
$navbar-height-mobile: 56px !default;
$navbar-line-height-mobile: $navbar-height-mobile !default;
$navbar-font-size: 1rem !default;
// $navbar-font-color: rgba(0, 0, 0, 0.87) !default;
$navbar-font-color: #fff !default;
$navbar-brand-font-size: 2.1rem !default;

// 14. Side Navigation
// ==========================================================================

$sidenav-width: 300px !default;
$sidenav-font-size: 14px !default;
$sidenav-font-color: rgba(0, 0, 0, 0.87) !default;
$sidenav-bg-color: #fff !default;
$sidenav-padding: 16px !default;
$sidenav-item-height: 48px !default;
$sidenav-line-height: $sidenav-item-height !default;

// 15. Photo Slider
// ==========================================================================

$slider-bg-color: color("grey", "base") !default;
$slider-bg-color-light: color("grey", "lighten-2") !default;
$slider-indicator-color: color("green", "base") !default;

// 16. Spinners | Loaders
// ==========================================================================

$spinner-default-color: $secondary-color !default;

// 17. Tabs
// ==========================================================================

$tabs-underline-color: $primary-color !default;
$tabs-text-color: $off-black !default;
$tabs-bg-color: $primary-color-light !default;

// 18. Tables
// ==========================================================================

$table-border-color: rgba(0, 0, 0, 0.12) !default;
$table-striped-color: rgba($primary-color-light, 0.1) !default;

// 19. Toasts
// ==========================================================================

$toast-height: 48px !default;
$toast-color: #323232 !default;
$toast-text-color: #fff !default;
$toast-action-color: #eeff41;

// 20. Typography
// ==========================================================================

// $font-stack: "Cuprum", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;
$font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
  Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default;

// Header Styles
$h1-fontsize: 2.92rem !default;
$h2-fontsize: 2.28rem !default;
$h3-fontsize: 1.64rem !default;
$h4-fontsize: 1.15rem !default;
$h5-fontsize: 1rem !default;
$h6-fontsize: 1rem !default;

// 21. Footer
// ==========================================================================

$footer-font-color: #fff !default;
$footer-bg-color: $primary-color !default;
$footer-copyright-font-color: rgba(255, 255, 255, 0.8) !default;
$footer-copyright-bg-color: rgba(51, 51, 51, 0.08) !default;

// 22. Flow Text
// ==========================================================================

$range: $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;

// 23. Collections
// ==========================================================================

$collection-border-color: #e0e0e0 !default;
$collection-bg-color: #fff !default;
$collection-active-bg-color: $secondary-color !default;
$collection-active-color: lighten($secondary-color, 55%) !default;
$collection-hover-bg-color: #ddd !default;
$collection-link-color: $secondary-color !default;
$collection-line-height: 1.5rem !default;

// 24. Progress Bar
// ==========================================================================

$progress-bar-color: $secondary-color !default;
