.samecategory {
  // overflow: hidden;

  &__title {
    text-transform: uppercase;
    font-family: Cuprum, Arial, sans-serif;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
    color: #484848;
    font-size: 30px;
    line-height: 1;
    font-weight: 400;
    margin: 1.5rem 0.75rem;
  }
}
