.cusel {
  height: 44px;
  background: #fff;
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  z-index: 1;
}
.cuselFrameRight {
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  height: 100%;
  width: 22px;
  color: #8d8f92;
  font-size: 10px;
  text-align: center;
  background: #fff;
}
.cuselFrameRight i,
.cuselText {
  line-height: 44px;
}
.cuselText {
  height: 44px;
  padding: 0 0 0 10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 3;
  font-family: Cuprum;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}
* html .cuselText {
  height: 22px;
}
.cusel span {
  display: block;
  cursor: pointer;
  white-space: nowrap;
  zoom: 1;
  font-family: Cuprum;
  font-size: 14px;
  line-height: 44px;
  padding: 0 15px 0 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.cusel .cuselOptHover,
.cusel span:hover {
  background: #ff0;
  color: #000;
}
.cusel .cuselActive {
  background: #fdcf09;
  color: #fff;
  cursor: default;
}
.cuselOpen {
  z-index: 11;
}
.classDisCusel,
.classDisCusel .cuselFrameRight {
  background-image: url(/img/cusel/selects-2-dis.png) !important;
  cursor: default;
  color: #ccc;
}
.classDisCusel .cuselText {
  cursor: default;
}
.cusel .cusel-scroll-wrap {
  display: block;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  min-width: 100%;
  width: auto;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}
.cusel .jScrollPaneContainer {
  position: relative;
  overflow: hidden;
  border: 1px solid #999;
}
.cusel .jScrollPaneTrack {
  height: 100%;
  width: 7px !important;
  background: #ccc;
  position: absolute;
  top: 0;
  right: 4px;
}
.cusel .jScrollPaneDrag {
  position: absolute;
  background-position: -40px -26px;
  cursor: pointer;
  width: 15px !important;
  height: 27px !important;
  right: -4px;
}
.cusel .jScrollPaneDragTop {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.cusel .jScrollPaneDragBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.cusel .jScrollArrowUp {
  top: 0;
  right: 2px;
  width: 26px;
  background-position: -2px -26px;
}
.cusel .jScrollArrowDown,
.cusel .jScrollArrowUp {
  position: absolute;
  height: 12px;
  cursor: pointer;
  overflow: hidden;
}
.cusel .jScrollArrowDown {
  width: 25px;
  top: auto;
  bottom: 0;
  right: 3px;
  background-position: -21px -26px;
}