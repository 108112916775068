.cmenu {
  background: #fdcf09;
  transition: 0.3s;
  text-align: left;

  a {
    font-family: Cuprum, Arial, sans-serif;
    font-size: 17px;
  }

  table {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    td {
      vertical-align: top;
      width: 20%;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      line-height: 1;

      li {
        margin: 0;
        padding: 15px;
        position: relative;

        &.hover {
          background: #ffff53;
          z-index: 2;

          a {
            &.active {
              // background: #fdcf09;
            }
          }

          ul {
            position: absolute;
            display: block !important;
            background: #ffff53;
            width: 100%;
            margin: 0;
            top: 100%;
            left: 0;
            padding-bottom: 10px;

            li {
              margin: 0 15px 5px;

              &.active {
                margin: 6px 15px;
              }
            }
          }
        }

        a {
          font-size: 17px;
          transition: all 0.3s ease-in-out;
          font-family: Cuprum;
          color: #000;
          padding: 5px 0;
          display: inline-block;

          &.active {
            // background: none repeat scroll 0 0 #cea700;
            background: #fff;
            // border: 2px inset #000;
            border: 2px solid #000;
            border-radius: 5px;
            // color: #fff;
            padding: 3px;
            text-decoration: none;
            display: inline-block;
          }
        }
        ul {
          margin: 5px 0;
          li {
            margin-top: 3px;
            padding: 0;
            a {
              font-size: 14px;
              padding: 0;
            }
          }
        }
      }
    }
  }

  &__list {
    list-style: none;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0;
    line-height: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    @media only screen and (max-width: 375px) {
      flex-direction: column;
    }

    li {
      margin: 0;
      padding: 15px;
      position: relative;
      flex-basis: 100%;

      @media only screen and (min-width: 426px) {
        flex-basis: 50%;
      }
      @media only screen and (min-width: 641px) {
        flex-basis: 33.3333%;
      }
      @media only screen and (min-width: 800px) {
        flex-basis: 25%;
      }
      @media only screen and (min-width: 1024px) {
        flex-basis: 20%;
      }

      &.collapsed {
        // @media only screen and (max-width: 375px) {
        //   display: none;
        // }
        @media only screen and (max-width: 425px) {
          display: none;
        }
      }

      a {
        font-size: 17px;
        transition: all 0.3s ease-in-out;
        font-family: Cuprum;
        color: #000;
        padding: 5px 0;
        display: inline-block;

        &.active {
          // background: none repeat scroll 0 0 #cea700;
          background: #fff;
          // border: 2px inset #000;
          border: 2px solid #000;
          border-radius: 5px;
          // color: #fff;
          padding: 3px;
          text-decoration: none;
          display: inline-block;
        }
      }
      ul {
        margin: 5px 0;
        li {
          margin-top: 3px;
          padding: 0;
          a {
            font-size: 14px;
            padding: 0;
          }
        }
      }

      &.hover {
        background: #ffff53;
        z-index: 2;

        a {
          &.active {
            // background: #fdcf09;
          }
        }

        ul {
          position: absolute;
          display: block !important;
          background: #ffff53;
          width: 100%;
          margin: 0;
          top: 100%;
          left: 0;
          padding-bottom: 10px;

          li {
            margin: 0 15px 5px;

            &.active {
              margin: 6px 15px;
            }
          }
        }
      }
    }
  }

  #toggleButton {
    text-align: center;
    padding: 0.75rem 0;
    clear: both;
    float: none;
    width: 100%;
    display: block;
    position: sticky;
    bottom: 0;
    background: $secondary-color;
    background: linear-gradient(0deg, $secondary-color 0%, $secondary-color 50%, rgba(253,208,9,0.26) 100%);
  }
}
