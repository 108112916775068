.paginator {
  font-family: Cuprum, Arial, sans-serif;
  margin: 1.5rem 0.75rem 1rem;
}
.paginatorPages {
  float: left;
}
.paginatorPages a,
.paginatorPages span {
  font-size: 16px;
  display: inline-block;
  background: transparent url(/img/pages.png) no-repeat scroll 0 0;
  width: 30px;
  overflow: hidden;
  /* padding: 5px 0; */
  margin-right: 0.5rem;
  line-height: 30px;
  height: 30px;
  /* float: left; */
  text-align: center;
  text-decoration: none;
  transition: all 0.1s ease-out 0s;
}
.paginatorPages a:active,
.paginatorPages a:focus,
.paginatorPages a:hover {
  background-position: -76px 0;
  border-radius: 15px;
  box-shadow: 2px 2px 7px #ccc;
  transform: rotate(10deg) scale(1.4);
  z-index: 5;
}
.paginatorPages span.active {
  background-position: -38px 0;
}
.paginatorLimit {
  float: right;
  margin: 0;
  line-height: 37px;
}
.paginatorLimit a {
  color: $off-black;
  padding: 1px 3px;
  margin: 0 2px;
  
  text-decoration: none;
  transition: all 0.5s ease-out;

}
.paginatorLimit a.active {
  background: #ffd925;
}
.paginatorLimit a:hover {
  background: #ff0;
}
.pages_arr {
  float: left;
  margin: 9px 0 0 20px;
}
.pages_arr a,
.pages_arr span {
  display: block;
  float: left;
  height: 12px;
  width: 12px;
  background: transparent url(/img/pages_arr.png) no-repeat scroll 0 0;
}
.pages_arr a.left {
  background-position: 0 -12px;
}
.pages_arr a.right {
  background-position: -14px 0;
}
.pages_arr span.disabled_right {
  background-position: -14px -12px;
}
