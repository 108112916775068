.scroll {
  background-color: #fff;
  border-top: 1px solid #eee;
  overflow: hidden;
  border-top: none;

  &__top {
    border-top: none;
    // display: none;
    display: inline-block;
    left: 50%;
    margin: 10px 0;
    overflow: unset;
    position: relative;
    transform: translateX(-50%);

    @media #{$small-and-down} {
      display: inline-block;
    }

    a {
      text-align: center;
      color: #1967d2;
      text-decoration: none;

      border-radius: 4px;
      box-sizing: border-box;
      display: inline-block;
      margin: 0;
      padding: 10px 23px;
      text-align: center;
      width: 100%;

      background-color: #fff;
      border: 1px solid #dadce0;
      color: #1a73e8;
      cursor: pointer;
      font-family: "Roboto-Medium", Arial, Sans-Serif-Medium, Sans-Serif;
      font-size: 14px;
      line-height: 20px;

      img {
        vertical-align: middle;
        padding-left: 8px;
        height: 18px;
      }
    }
  }
}
