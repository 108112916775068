nav {
  position: sticky;
  left: 0;
  top: 0;
  color: $navbar-font-color;
  @extend .z-depth-1;
  width: 100%;
  height: $navbar-height-mobile;
  line-height: $navbar-line-height-mobile;
  z-index: 99;
  // background-color: rgba(76, 76, 76, .72);y
  background-color: $primary-color;
  background-color: rgba(29, 29, 31, 0.79);
  backdrop-filter: saturate(180%) blur(20px);
  // transition: background-color 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);

  .row {
    margin-bottom: 0;
  }

  &.nav-extended {
    height: auto;

    .nav-wrapper {
      min-height: $navbar-height-mobile;
      height: auto;
    }

    .nav-content {
      position: relative;
      line-height: normal;
    }
  }

  a {
    color: $navbar-font-color;
  }

  i,
  [class^="mdi-"],
  [class*="mdi-"],
  i.material-icons {
    display: block;
    font-size: 24px;
    height: $navbar-height-mobile;
    line-height: $navbar-line-height-mobile;

    img.svg {
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }
  }

  .nav-wrapper {
    position: relative;
    height: 100%;
  }

  @media #{$large-and-up} {
    a.sidenav-trigger {
      display: none;
    }
  }

  // Collapse button
  .sidenav-trigger {
    float: left;
    position: relative;
    z-index: 1;
    height: $navbar-height-mobile;
    margin: 0 18px;
    text-decoration: none;
    color: $navbar-font-color;

    &:hover {
      color: $navbar-font-color;
    }

    i {
      height: $navbar-height-mobile;
      line-height: $navbar-line-height-mobile;
    }
  }

  // Logo
  .brand-logo {
    color: $navbar-font-color;
    display: inline-block;
    font-size: $navbar-brand-font-size;
    padding: 0;
    margin-right: 0.5rem;
    margin-top: 0.75rem;

    a {
      display: block;
      height: 40px;
    }

    img {
      height: 40px;
    }

    &.center {
      left: 50%;
      transform: translateX(-50%);
    }

    @media #{$medium-and-down} {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 0.5rem;
      transform: translateX(-50%);

      &.left,
      &.right {
        padding: 0;
        // transform: none;
      }

      &.left {
        // left: 0.5rem;
      }
      &.right {
        right: 0.5rem;
        left: auto;
      }
      img {
        height: 36px;
      }
    }

    &.right {
      right: 0.5rem;
      padding: 0;
    }

    i,
    [class^="mdi-"],
    [class*="mdi-"],
    i.material-icons {
      float: left;
      margin-right: 15px;
    }
  }

  // Title
  .nav-title {
    display: inline-block;
    font-size: 32px;
    padding: 28px 0;
  }

  // Navbar Links
  ul {
    margin: 0;

    li {
      transition: background-color 0.3s;
      float: left;
      padding: 0;

      &.active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    a {
      transition: background-color 0.3s;
      font-size: $navbar-font-size;
      color: $navbar-font-color;
      display: block;
      padding: 0 15px;
      cursor: pointer;
      text-decoration: none;

      &.btn,
      &.btn-large,
      &.btn-flat,
      &.btn-floating {
        margin-top: -2px;
        margin-left: 15px;
        margin-right: 15px;

        & > .material-icons {
          height: inherit;
          line-height: inherit;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    &.left {
      float: left;
    }
  }

  // Navbar Search Form
  form {
    height: 100%;
  }

  .input-field {
    margin: 0;
    height: 100%;

    input {
      height: 100%;
      font-size: 1.2rem;
      border: none;
      padding-left: 2rem;

      &:focus,
      &[type="text"]:valid,
      &[type="password"]:valid,
      &[type="email"]:valid,
      &[type="url"]:valid,
      &[type="date"]:valid {
        border: none;
        box-shadow: none;
      }
    }

    label {
      top: 0;
      left: 0;

      i {
        color: rgba(255, 255, 255, 0.7);
        transition: color 0.3s;
      }
      &.active i {
        color: $navbar-font-color;
      }
    }
  }
}

// Fixed Navbar
.navbar-fixed {
  position: relative;
  height: $navbar-height-mobile;
  z-index: 997;

  nav {
    position: fixed;
  }
}
@media #{$medium-and-up} {
  nav.nav-extended .nav-wrapper {
    min-height: $navbar-height;
  }
  nav,
  nav .nav-wrapper i,
  nav a.sidenav-trigger,
  nav a.sidenav-trigger i {
    height: $navbar-height;
    line-height: $navbar-line-height;
  }
  .navbar-fixed {
    height: $navbar-height;
  }
}
