#site_conteiner {
  position: relative;
  height: auto !important;
  height: 100%;
  min-height: 100%;
  width: 100%;
  margin: 0 auto;
  // font-size: 1.2em;
  text-align: center;
  overflow: hidden;
  padding-bottom: 0.75rem;
  // flex: 1;
}
#top_line {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background: transparent url(/img/top_line.png) repeat-x scroll 0 0;
  text-align: center;
  z-index: 99;
  // padding-bottom: 8px;
  height: 44px;
  // min-width: 442px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}
#top_line_inner {
  text-align: left;
}

// #wrapper {
//   padding-bottom: 135px;
// }
// #wrapper,
// #wrapper_main {
//   text-align: left;
//   max-width: 957px;
//   margin: 0 auto;
// }
// #wrapper_main {
//   padding-bottom: 24px;
//   margin-top: 30px;
// }
// #content_area,
// html #content {
//   width: 100%;
// }

// #footer {
//   height: 80px;
//   // min-width: 957px;
// }
// #footer,
// #footer_main {
//   background: #fff;
// }
// #footer_inner {
//   text-align: left;
//   // width: 957px;
//   margin: 0 auto;
//   overflow: hidden;
// }
// #footer_main #footer_inner {
//   border-top: 1px solid #ccc;
// }
