// .container {
//   padding-left: 1.5rem;
//   padding-right: 1.5rem;
//   width: 100%;
//   text-align: left;
//   margin: 0 auto;

//   > .row {
//     margin-right: -.75rem;
//     margin-left: -.75rem;
//   }

//   @media #{$small-and-down} {
//     width: 100%;
//     padding-left: .75rem;
//     padding-right: .75rem;
//     // styles for small screens and down
//   }
//   // @media #{$small-only} {
//   //   width: 100%;
//   //   // styles for small screens and down
//   // }
//   // @media #{$medium-and-up} {
//   //   // styles for medium screens and larger
//   //   width: 750px;
//   // }
//   @media #{$small-only} {
//     // styles for medium screens and down
//     // width: 451px;
//   }
//   @media #{$medium-only} {
//     // max-width: 100%;
//     // styles for medium screens and larger
//   }
//   @media #{$large-and-up} {
//     // styles for large screens and up
//     // width: 970px;
//   }
//   @media #{$extra-large-and-up} {
//     // styles for extra large screens and up
//     max-width: 1408px;
//   }
// }

.container {
  margin: 0 auto;
  // max-width: 1280px;
  max-width: 1280px;
  width: 90%;
  // text-align: left;
}
@media #{$medium-and-up} {
  .container {
    width: 85%;
  }
}
@media #{$large-and-up} {
  .container {
    width: 80%;
  }
}
.col .row {
  margin-left: (-1 * $gutter-width / 2);
  margin-right: (-1 * $gutter-width / 2);
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.no-pad {
    padding: 0;
  }
  &.no-pad-bot {
    padding-bottom: 0;
  }
  &.no-pad-top {
    padding-top: 0;
  }
}


// Mixins to eliminate code repitition
@mixin reset-offset {
  margin-left: auto;
  left: auto;
  right: auto;
}
@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }
  &.pull-#{$size}#{$i} {
    right: $perc;
  }
  &.push-#{$size}#{$i} {
    left: $perc;
  }
}


.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    float: left;
    box-sizing: border-box;
    padding: 0 $gutter-width / 2;
    min-height: 1px;

    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.s#{$i} {
        width: $perc;
        @include reset-offset;
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      @include grid-classes("s", $i, $perc);
      $i: $i + 1;
    }

    @media #{$medium-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.m#{$i} {
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("m", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$large-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.l#{$i} {
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("l", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$extra-large-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.xl#{$i} {
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("xl", $i, $perc);
        $i: $i + 1;
      }
    }
  }
}
